import {
  LoginDto, ResetPasswordModel, UserPostDto,
} from '@alexnoriega3/terravital_api';
import Swal from 'sweetalert2';
import Compressor from 'compressorjs';

import {
  AccountController, axiosInstance, UserController,
} from '../../../services/apiTerraVital';
import {
  checkingCredentials, logout, login, updateUserData, updateDoctorData, _uploadImage, _selectedDoctor,
} from './index';
import { finishLoading, startLoading } from '../ui';
import { resetLoadings } from '../profile';
import { isCompleteProfile, profileErrorMsg } from '../../../utils/Validate';

export const checkingAuthentication = () => async (dispatch: any) => {
  dispatch(checkingCredentials());
};

export const startLoginEmailPassword = ({ email, password }: LoginDto) => async (dispatch: any) => {
  dispatch(checkingCredentials());
  dispatch(resetLoadings());

  try {
    dispatch(startLoading());
    const result = await AccountController.apiAccountLoginPost({ email, password });
    if (result.status == 200) {
      await setInstance(result.data);
      dispatch(login(result.data));
    } else {
      Swal.fire('Error', 'Login error', 'error');
    }
    dispatch(finishLoading());
  } catch (error: any) {
    Swal.fire('Error', error.response.data, 'error');
    dispatch(finishLoading());
  }
};

const setInstance = async ({ token }: any) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  axiosInstance.defaults.headers = headers;
};

export const startLogout = () => async (dispatch: any) => {
  // await AccountController.apiAccountLogoutPost();

  dispatch(logout({
    payload: {
      errorMessage: '',
    },
  }));
};

export const startLoginEmailPassword_OLD = (credentials: LoginDto) => (dispatch: any) => {
  dispatch(startLoading());
  AccountController.apiAccountLoginPost(credentials)
    .then((result: any) => {
      if (result.status == 200) {
        dispatch(login(result.data));
      } else {
        Swal.fire('Error', 'Login error', 'error');
      }

      dispatch(finishLoading());
    })
    .catch((e) => {
      Swal.fire('Error', e.message, 'error');
      dispatch(finishLoading());
    });
};

export const startRegisterWithEmailPassword = (userRegister: UserPostDto) => async (
  dispatch: any,
) => {
  try {
    const result = await UserController.apiUserPost(userRegister);

    if (result.status == 200) {
      dispatch(logout({
        payload: {
          errorMessage: '',
        },
      }));
      Swal.fire('¡Éxito!', 'Se ha registrado correctamente.', 'success').then((okay) => {
        if (okay) { window.location.replace('/login'); }
      });
    } else {
      Swal.fire('Error', 'register Error', 'error');
    }
  } catch (e: any) {
    Swal.fire('Error', `Ya existe una cuenta con este correo ${userRegister.email}`, 'error');
  }
};

export const forgotPasswordWithEmail = (email: string) => (dispatch: any) => {
  AccountController.apiAccountForgotpasswordPost(email)
    .then((result: any) => {
      if (result.status == 200) {
        dispatch(logout({
          payload: {
            errorMessage: '',
          },
        }));
        Swal.fire('¡Éxito!', '', 'success');
      } else {
        Swal.fire('Error', 'Error al enviar el correo', 'error');
      }
    })
    .catch(() => {
      Swal.fire('Error', 'Error al enviar el correo', 'error');
    });
};

export const resetPassword = (credentials: ResetPasswordModel) => (dispatch: any) => {
  AccountController.apiAccountResetpasswordPost(credentials)
    .then((result: any) => {
      if (result.status == 200) {
        dispatch(logout({
          payload: {
            errorMessage: '',
          },
        }));
        Swal.fire('¡Éxito!', 'Se ha guardado su nueva contraseña', 'success').then((okay) => {
          if (okay) { window.location.replace('/login'); }
        });
      } else {
        Swal.fire('Error', 'Reset Password Error', 'error');
      }
    })
    .catch((e) => {
      Swal.fire('Error', e.message, 'error');
    });
};

export const getUserByID = (userId: string, providerRole: string) => async (dispatch: any) => {
  try {
    dispatch(startLoading());
    const result = await UserController.apiUserIdGet(userId);

    if (result.status == 200) {
      dispatch(updateUserData(result.data));
      if (!isCompleteProfile(result.data, providerRole)) {
        Swal.fire('Perfil incompleto', 'Para generar una cita es necesario completar su perfil.', 'info').then((okay) => {
          if (okay) { window.location.replace('/profile/edit'); }
        });
      }
    } else {
      Swal.fire('Error', 'Error get user', 'error');
    }

    dispatch(finishLoading());
  } catch (e: any) {
    Swal.fire('Error', e.message, 'error');
    dispatch(finishLoading());
  }
};

export const editUser = (userId: string, credentials: any) => async (dispatch: any) => {
  dispatch(startLoading());

  try {
    if (credentials.imageFile.name != null && credentials.imageFile.name != '') {
      credentials.imageFile = await new Promise((resolve) => {
        // eslint-disable-next-line no-new
        new Compressor(credentials.imageFile, {
          convertTypes: [
            'image/jpeg',
            'image/jpg',
            'image/png',
            'image/webp',
            'image/bmp',
            'image/tiff',
          ],
          quality: 0.6,
          convertSize: 100,
          maxWidth: 300,
          maxHeight: 300,
          minWidth: 250,
          minHeight: 250,
          success: (compressedResult: any) => {
            resolve(compressedResult);
          },
        });
      });
    } else {
      credentials.imageFile = undefined;
    }

    const result = await UserController.apiUserIdPutForm(
      userId,
      credentials.imageFile,
      credentials.name,
      credentials.firstName,
      credentials.lastName,
      credentials.url,
      credentials.countryCode,
      undefined,
      credentials.gender,
      credentials.phone,
      credentials.country,
      credentials.city,
      credentials.address,
      credentials.birthDate,
    );

    if (result.status == 200) {
      credentials.image = result.data.image;
      dispatch(updateUserData(credentials));
      Swal.fire('¡Éxito!', 'Se ha guardado correctamente', 'success');
    } else {
      Swal.fire('Error', 'edit user Error', 'error');
    }

    dispatch(finishLoading());
  } catch (e: any) {
    Swal.fire({
      title: 'Error',
      html: profileErrorMsg(e.response.data.errors),
      icon: 'error',
    });
    dispatch(finishLoading());
  }
};

export const getDoctorProfile = (providerId: string, providerRole: string) => async (dispatch: any) => {
  try {
    dispatch(startLoading());
    const result = await UserController.apiUserProviderProfileProviderIdPost(providerId);

    if (result.status == 200) {
      dispatch(updateDoctorData(result.data));
      if (!isCompleteProfile(result.data, providerRole)) {
        Swal.fire('Perfil incompleto', 'Para generar una cita es necesario completar su perfil.', 'info').then((okay) => {
          if (okay) { window.location.replace('/profile/doctor/edit'); }
        });
      }
    } else {
      Swal.fire('Error', 'Error get user', 'error');
    }

    dispatch(finishLoading());
  } catch (e: any) {
    Swal.fire('Error', e.message, 'error');
    dispatch(finishLoading());
  }
};

export const editDoctorProfile = (providerId: string, credentials: any) => async (dispatch: any) => {
  try {
    dispatch(startLoading());

    const result = await UserController.apiUserEditProviderIdPut(providerId, credentials);

    if (result.status == 200) {
      dispatch(updateDoctorData(credentials));
      Swal.fire('¡Éxito!', 'Se ha guardado correctamente', 'success');
    } else {
      Swal.fire('Error', 'register Error', 'error');
    }

    dispatch(finishLoading());
  } catch (e: any) {
    Swal.fire({
      title: 'Error',
      html: profileErrorMsg(e.response.data.errors),
      icon: 'error',
    });
    dispatch(finishLoading());
  }
};

export const uploadImage = (userId: string, image: any) => async (dispatch: any) => {
  dispatch(startLoading());
  try {
    if (image.name != null && image.name != '') {
      image = await new Promise((resolve) => {
        // eslint-disable-next-line no-new
        new Compressor(image, {
          convertTypes: [
            'image/jpeg',
            'image/jpg',
            'image/png',
            'image/webp',
            'image/bmp',
            'image/tiff',
          ],
          quality: 0.6,
          convertSize: 100,
          maxWidth: 300,
          maxHeight: 300,
          minWidth: 250,
          minHeight: 250,
          success: (compressedResult: any) => {
            resolve(compressedResult);
          },
        });
      });

      const result = await UserController.apiUserUploadImageIdPostForm(userId, image);

      if (result.status == 200) {
        dispatch(_uploadImage(result.data.image));
      } else {
        Swal.fire('Error', 'Imagen no valida', 'error');
      }
    }
    dispatch(finishLoading());
  } catch (e: any) {
    Swal.fire('Error', e.message, 'error');
    dispatch(finishLoading());
  }
};

export async function getAssignedDoctors(id: string) {
  try {
    const result = await axiosInstance.get(`/api/User/GetAssignedDoctors/${id}`);

    if (result.status == 200) {
      return result.data;
    }
    return null;
  } catch (e: any) {
    Swal.fire('Error', e.message, 'error');
    return null;
  }
}

export const selectedDoctorProfile = (providerId: string) => async (dispatch: any) => {
  try {
    dispatch(startLoading());
    const result = await UserController.apiUserProviderProfileProviderIdPost(providerId);

    if (result.status == 200) {
      dispatch(_selectedDoctor(result.data));

      setTimeout(() => {
        localStorage.setItem('homeDoctorVisitLoading', 'false');
        localStorage.setItem('nextPendingLoading', 'false');
        window.location.replace('/home');
      }, 1800);
    } else {
      Swal.fire('Error', 'Error get user', 'error');
    }

    dispatch(finishLoading());
  } catch (e: any) {
    Swal.fire('Error', e.message, 'error');
    dispatch(finishLoading());
  }
};
