/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-lone-blocks */
/* eslint-disable import/no-unresolved */
import * as React from 'react';
import Box from '@mui/material/Box';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import {
  TextField, Stack, MenuItem, CardContent,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { SearchUser } from '../../../../redux/store/profile';
import { departmentsListDTO } from '../../profile.model';
import TableDoctorData from '../doctor/doctorTableData';
import PageTitle from '../../../../components/shared/PageTitle';
import NoSearchResult from '../../../../components/shared/NoSearchResult';
import { LoadingBoxProgress } from '../../../../utils/loadingsFormats';

export default function SearchDoctorView(props: { department: string; }) {
  const { department } = props;
  const State = useSelector((GenState: any) => GenState);

  const [value, setValue] = React.useState(department != null ? department : 'todos');
  const [search, setSearch] = React.useState('');
  const [searchList, setSearchList] = React.useState<any>([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [isLoadingMore, setIsLoadingMore] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const options: departmentsListDTO[] = [{ departmentId: 'todos', name: 'Todos' }];

  const timerRef = React.useRef<number | null>(null);

  const { auth: { roles }, visit } = State;

  const getSearchDTO = (searchParam: string, pageParam: number) => ({
    page: pageParam + 1,
    search: searchParam,
    pageSize: isMobile ? 10 : 5,
    departments: value !== 'todos' ? [value] : [],
    subDepartments: [],
    role: 'Proveedor',
  });

  visit.departmentsList.forEach((list: departmentsListDTO) => {
    options.push(list);
  });

  const performSearch = async (searchParam = search, pageParam = page, resetList = false) => {
    if (resetList) {
      setIsLoading(true);
    }
    try {
      const searchDTO = getSearchDTO(searchParam, pageParam);

      const result = await SearchUser(searchDTO, roles[0]);
      if (result != null) {
        if (!resetList) {
          setSearchList((prevList: any[]) => [...prevList, ...result.items]);
        } else {
          setSearchList(result.items);
        }
        setTotalCount(result.totalCount);
      }
    } catch (error) {
      console.error(error);
    } finally {
      if (resetList) {
        setIsLoading(false);
      } else {
        setIsLoadingMore(false);
      }
    }
  };

  React.useEffect(() => {
    setIsLoading(true);
    performSearch(search, 0, true).catch(console.error);
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearch = e.target.value;
    setSearch(newSearch);
    setPage(0);
    setSearchList([]);
    setIsLoading(true);

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = window.setTimeout(async () => {
      await performSearch(newSearch, 0, true);
    }, 500);
  };

  const handleDepartmentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setValue(newValue);
    setPage(0);
    setSearchList([]);
    setIsLoading(true);
  };

  const handleLoadMore = async () => {
    setIsLoadingMore(true);
    const newPage = page + 1;
    setPage(newPage);
    await performSearch(search, newPage, false);
  };

  const handlePageChange = async (newPage: number) => {
    setPage(newPage);
    await performSearch(search, newPage, true);
  };

  return (
    <>
      <PageTitle kTitle="Doctores" />
      <CardContent>
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <TextField
              size="small"
              variant="outlined"
              placeholder="Busca a un especialista"
              autoComplete="search"
              sx={{ width: '40%' }}
              value={search}
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment sx={{ cursor: 'pointer' }} position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                inputProps: {
                  maxLength: 25,
                },
              }}
            />
            {visit.departmentsList && visit.departmentsList.length > 0 && (
              <TextField
                size="small"
                select
                value={value}
                onChange={handleDepartmentChange}
                sx={{ border: 0 }}
              >
                {options.map((option: departmentsListDTO) => (
                  <MenuItem key={option.departmentId} value={option.departmentId}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Stack>
          <Box
            sx={{
              py: 2, height: '100%',
            }}
          >
            {isLoading ? (
              <LoadingBoxProgress
                styleBox={{
                  display: 'flex', justifyContent: 'center', py: 2, pt: '100px', height: '450px',
                }}
                colorLoading="primary"
              />
            ) : searchList && searchList.length > 0 ? (
              <TableDoctorData
                ListData={searchList}
                totalCount={totalCount}
                page={page}
                hasMore={(page + 1) * 5 < totalCount}
                onSubmit={handlePageChange}
                isMobile={false}
                isLoadingMore={false}
              />
            ) : (
              <NoSearchResult text="No se encontraron resultados" />
            )}
          </Box>
        </Box>
        <Box component="main" sx={{ display: { xs: 'block', md: 'none' } }}>
          <TextField
            size="small"
            variant="outlined"
            placeholder="Busca a un especialista"
            autoComplete="search"
            fullWidth
            value={search}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment sx={{ cursor: 'pointer' }} position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              inputProps: {
                maxLength: 25,
              },
            }}
          />
          <Box sx={{ py: 2 }}>
            {isLoading && page === 0 ? (
              <LoadingBoxProgress
                styleBox={{ display: 'flex', justifyContent: 'center', py: 2 }}
                colorLoading="primary"
              />
            ) : searchList && searchList.length > 0 ? (
              <TableDoctorData
                ListData={searchList}
                totalCount={totalCount}
                page={page}
                hasMore={searchList.length < totalCount}
                onLoadMore={handleLoadMore}
                isLoadingMore={isLoadingMore}
                isMobile
              />
            ) : (
              <NoSearchResult text="No se encontraron resultados" />
            )}
          </Box>
        </Box>
      </CardContent>
    </>
  );
}
