import { Typography } from '@mui/material';
import moment from 'moment';

export function isValidEmail(email: any) {
  return /\S+@\S+\.\S+/.test(email);
}

export function removeAccents(str: any) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function capitalizeFirstLetter(str: any) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function isValidPhone(phone: any) {
  let isValid = true;
  if (phone.length < 11) {
    isValid = /^[0-9\b]+$/.test(phone);
  } else {
    isValid = false;
  }
  return isValid;
}

export function isValidSchedule(schedule: any) {
  const isValid = schedule.filter((item: any) => {
    if (item.hourOpen == item.hourClose) {
      return item;
    } if (parseFloat(item.hourOpen) > parseFloat(item.hourClose)) { return item; }
    if (item.secondHourOpen != '' && item.secondHourClose != '') {
      if (item.secondHourOpen == item.secondHourClose || item.secondHourOpen == item.hourOpen
        || item.secondHourOpen == item.hourClose) {
        return item;
      } if (parseFloat(item.secondHourOpen) > parseFloat(item.secondHourClose)
      || parseFloat(item.secondHourOpen) < parseFloat(item.hourOpen)
      || parseFloat(item.secondHourOpen) < parseFloat(item.hourClose)) {
        return item;
      } if (parseFloat(item.secondHourClose) < parseFloat(item.secondHourOpen)
      || parseFloat(item.secondHourClose) < parseFloat(item.hourOpen)
      || parseFloat(item.secondHourClose) < parseFloat(item.hourClose)) { return item; }
      return null;
    }
    return null;
  }).map((value: any) => ({
    day: value.day,
    hourOpen: value.hourOpen,
    hourClose: value.hourClose,
    secondHourOpen: value.secondHourOpen,
    secondHourClose: value.secondHourClose,
  }));

  return isValid;
}

export function getErrorHourMessage(schedule: any) {
  let meesage = '';
  if (schedule.secondHourOpen != '' && schedule.secondHourClose != '') {
    if (parseFloat(schedule.secondHourOpen) < parseFloat(schedule.hourOpen)
    || parseFloat(schedule.secondHourOpen) < parseFloat(schedule.hourClose)) {
      meesage = `El segundo horario debe ser después del primer horario, el día ${schedule.day}`;
    } else if (schedule.secondHourOpen == schedule.hourOpen
      || schedule.secondHourOpen == schedule.hourClose) {
      meesage = `El segundo horario debe ser después del primer horario, el día ${schedule.day}`;
    } else if (parseFloat(schedule.secondHourOpen) > parseFloat(schedule.secondHourClose)) {
      meesage = `El segundo horario de cierre debe ser mayor a la segunda hora de inicio en el día ${schedule.day}`;
    } else if (parseFloat(schedule.hourOpen) > parseFloat(schedule.hourClose)) {
      meesage = `La hora de cierre debe ser mayor a la hora de inicio en el día ${schedule.day}`;
    } else if (schedule.hourOpen == schedule.hourClose) {
      meesage = `La hora de cierre debe ser mayor a la hora de inicio en el día ${schedule.day}`;
    }
  } else if (parseFloat(schedule.hourOpen) > parseFloat(schedule.hourClose)) {
    meesage = `La hora de cierre debe ser mayor a la hora de inicio en el día ${schedule.day}`;
  } else if (schedule.hourOpen == schedule.hourClose) {
    meesage = `La hora de cierre debe ser mayor a la hora de inicio en el día ${schedule.day}`;
  }
  return meesage;
}

export function isCompleteProfile(user: any, role: any) {
  let isValid = true;
  switch (role) {
    case 'Proveedor':
      isValid = user.department[0] != null && user.subDepartments[0] != null && user.schedules[0] != null;
      break;
    default:
      isValid = user.name != '' && user.phone != null;
      break;
  }
  return isValid;
}

export function profileErrorMsg(errors: any) {
  const errorMessages: any = [];
  Object.entries(errors).forEach(([key, value]) => {
    switch (key) {
      case 'phone':
        errorMessages.push(`Celular: ${value}`);
        break;
      default:
        errorMessages.push(value);
        break;
    }
  });
  const fullErrorMessage = errorMessages.join('<br />');
  return fullErrorMessage;
}

export function Cancelled(cancelled: any) {
  let option = '';
  if (cancelled == 'Imprevisto') {
    option = 'Imprevisto de última hora';
  } else if (cancelled == 'Confusion') {
    option = 'Confusión con la fecha/hora';
  } else if (cancelled == 'Resolucion') {
    option = 'Resolución de síntomas';
  }
  return option;
}

export function canceledBy(userRole: any, detailVisit: any) {
  const userName = userRole == 'Proveedor'
    ? `${detailVisit.userName}`
    : `${detailVisit.doctorName}`;

  return userName;
}

export function getFormatDate(date: any, hour: any, type?: any) {
  const day = moment(date, ['YYYY-MM-DD']).format('DD');
  const month = moment(date, ['YYYY-MM-DD']).format('MMMM');
  const year = moment(date, ['YYYY-MM-DD']).format('YYYY');
  let newDate = '';

  switch (type) {
    case 1:
      newDate = `${day} de ${capitalizeFirstLetter(month)} de ${year}`;
      break;
    case 2:
      newDate = moment(hour, ['h:mm']).format('h:mm A');
      break;
    default:
      newDate = `${day} de ${month} de ${year} - ${hour} hrs.`;
      break;
  }

  return newDate;
}

export function GetStatus(status: any) {
  if (status == 'Cancelada') {
    return (
      <Typography variant="body2" sx={{ fontWeight: 800 }} color="error">{status}</Typography>
    );
  } return (<Typography variant="body2" sx={{ fontWeight: 800 }} color="text.secondary">{status}</Typography>);
}

export function isValidPassword(pass: any) {
  let result = true;
  let messageError = '';
  const isDigit = /(?=.*?[0-9])/.test(pass);
  if (pass.length < 6 || pass.length > 24) {
    result = false;
    messageError = 'La contraseña debe tener al menos 6 caracteres.';
  } else if (!isDigit) {
    result = false;
    messageError = 'Debe incluir al menos un número.';
  }
  return { isValid: result, messageError };
}

export function isValidPasswordWithMayusAndMinus(pass: string) {
  let result = true;
  let messageError = '';

  const isDigit = /(?=.*?[0-9])/.test(pass); // Al menos un número
  const isUpperCase = /(?=.*?[A-Z])/.test(pass); // Al menos una mayúscula
  const isLowerCase = /(?=.*?[a-z])/.test(pass); // Al menos una minúscula
  const isSpecialChar = /(?=.*?[#?!@$%^&*-])/.test(pass); // Al menos un carácter especial

  if (pass.length < 6 || pass.length > 24) {
    result = false;
    messageError = 'Tu contraseña debe ser de 6 a 24 caracteres.';
  } else if (!isDigit) {
    result = false;
    messageError = 'Debe incluir al menos un número.';
  } else if (!isUpperCase) {
    result = false;
    messageError = 'Debe incluir al menos una letra mayúscula.';
  } else if (!isLowerCase) {
    result = false;
    messageError = 'Debe incluir al menos una letra minúscula.';
  } else if (!isSpecialChar) {
    result = false;
    messageError = 'Debe incluir al menos un carácter especial.';
  }

  return { isValid: result, messageError };
}

export function disableEditVisit(status: any, detailVisit: any) {
  let enable = true;
  const visitHour = moment(detailVisit.startHour, ['h:mm']).subtract(10, 'minutes').format('H:mm');
  const visitDate: any = moment(detailVisit.visitDate);
  const today = moment().format('YYYY-MM-DD');
  const hour = moment().format('H:mm');
  const minutes = moment().format('mm');

  switch (status) {
    case 'Pendiente':
      if (visitDate.format('YYYY-MM-DD') == today) {
        if (parseFloat(hour) == parseFloat(visitHour)) {
          if (parseFloat(minutes) >= parseFloat(moment(visitHour, ['H:mm']).format('mm'))) {
            enable = true;
          } else enable = false;
        } else if (parseFloat(hour) > parseFloat(visitHour)) {
          enable = true;
        } else enable = false;
      } else if (visitDate.format('YYYY-MM-DD') < today) {
        enable = true;
      } else enable = false;
      break;
    default:
      enable = true;
      break;
  }
  return enable;
}

export function VisitList(list: any, option: any) {
  moment.locale('es');
  const today = moment().format('YYYY-MM-DD');
  const actualDay = list.filter((item: { visitDate: string; }) => (moment(item.visitDate).format('YYYY-MM-DD') >= today)).map((value: any) => (value));
  const historicList = list.filter((item: { visitDate: string; }) => (moment(item.visitDate).format('YYYY-MM-DD') < today)).map((value: any) => (value));
  if (option == 0) {
    return actualDay;
  } return historicList;
}

export function AvailableDate(schedules: any, busyDates: any) {
  let newHour: any = moment();
  let isActiveDay;
  let newDate: any = moment();
  let hourOpen;
  moment.locale('es');
  const time = moment();
  const activeDays = schedules.map((val: any) => (removeAccents(val.day.toLowerCase())));

  isActiveDay = activeDays.includes(removeAccents(newDate.format('dddd')));

  while (!isActiveDay) {
    newDate = newDate.add(1, 'day');
    isActiveDay = activeDays.includes(removeAccents(newDate.format('dddd')));
  }
  if (isActiveDay) {
    hourOpen = schedules.filter((item: any) => (removeAccents(item.day.toLowerCase()) == removeAccents(newDate.format('dddd'))))
      .map((value: any) => (value.hourOpen));
  }

  if (removeAccents(newDate.format('dddd')) == removeAccents(time.format('dddd'))
    && parseFloat(time.format('H:mm')) >= parseFloat(hourOpen)) {
    newHour = time.format('H:mm');
    if (time.minutes() >= 20 && time.minutes() <= 35) newHour = moment(newHour, ['h']).add(90, 'minutes').format('H:mm');
    if (time.minutes() > 35) newHour = moment(newHour, ['h']).add(120, 'minutes');
    if (time.minutes() < 20) newHour = moment(newHour, ['h']).add(60, 'minutes');
  } else newHour = moment(hourOpen, ['h:mm']).format('H:mm');

  const busyHours = busyDates.filter((item: any) => (item.date == newDate.format('YYYY-MM-DD'))).map((value: any) => (value.startHour));
  const isReserved = busyHours[0] != null ? busyHours.includes(moment(newHour, ['h:mm']).format('HH:mm')) : false;
  let reserved;
  if (isReserved) {
    newHour = moment(newHour, ['h:mm']).add(30, 'minutes').format('H:mm');
    reserved = busyHours.includes(newHour);
    while (reserved) {
      newHour = moment(newHour, ['h:mm']).add(30, 'minutes').format('H:mm');
      reserved = busyHours.includes(newHour);
    }
    if (!reserved) newHour = moment(newHour, ['H:mm']).format('H:mm');
  }

  const datesAvailable = [
    { id: 0, date: newDate.format('DD/MM/YY'), hour: moment(newHour, ['H:mm']).format('H:mm') },
    { id: 1, date: newDate.format('DD/MM/YY'), hour: moment(newHour, ['H:mm']).add(30, 'minutes').format('H:mm') },
    { id: 2, date: newDate.format('DD/MM/YY'), hour: moment(newHour, ['H:mm']).add(60, 'minutes').format('H:mm') },
  ];

  return datesAvailable;
}
