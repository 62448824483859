/* eslint-disable no-nested-ternary */

import * as React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box, Button, TextField, Stack, CardContent, InputAdornment,
} from '@mui/material';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { isMobile } from 'react-device-detect';
import { SearchUser } from '../../../../redux/store/profile';
import TablePatientData from '../patient/patientTableData';
import NewPatientModal from './newPatientModal';
import PageTitle from '../../../../components/shared/PageTitle';
import CustomModal from '../../../../components/shared/Modal';
import { isCompleteProfile } from '../../../../utils/Validate';
import NoSearchResult from '../../../../components/shared/NoSearchResult';
import { LoadingBoxProgress } from '../../../../utils/loadingsFormats';

export default function SearchPatientView() {
  const State = useSelector((GenState: any) => GenState);
  const [openModal, setOpenModal] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const [searchList, setSearchList] = React.useState<any>([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [isLoadingMore, setIsLoadingMore] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const timerRef = React.useRef<number | null>(null);

  const { auth: user } = State;

  const getSearchByProvider = (searchParam: string, pageParam: number) => ({
    providerId:
      user.roles[0] === 'Subordinate'
        ? user.doctorProfileData.userId
        : user.userId,
    page: pageParam + 1,
    search: searchParam,
    pageSize: isMobile ? 10 : 5,
  });

  const performSearch = async (searchParam = search, pageParam = page, resetList = false) => {
    if (resetList) {
      setIsLoading(true);
    }
    try {
      const result = await SearchUser(
        getSearchByProvider(searchParam, pageParam),
        user.roles[0] === 'Subordinate' ? 'Proveedor' : user.roles[0],
      );
      if (result) {
        setTotalCount(result.totalCount);
        if (!resetList) {
          setSearchList((prevList: any) => [...prevList, ...result.items]);
        } else {
          setSearchList(result.items);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      if (resetList) {
        setIsLoading(false);
      } else {
        setIsLoadingMore(false);
      }
    }
  };

  React.useEffect(() => {
    setIsLoading(true);
    performSearch(search, 0, true).catch(console.error);
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearch = e.target.value;
    setSearch(newSearch);
    setPage(0);
    setSearchList([]);
    setIsLoading(true);

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = window.setTimeout(async () => {
      await performSearch(newSearch, 0, true);
    }, 500);
  };

  const handleClickOpen = () => {
    const userData = user;
    if (!isCompleteProfile(userData, 'Proveedor')) {
      Swal.fire(
        'Perfil incompleto',
        'Para añadir nuevo paciente es necesario completar su perfil.',
        'info',
      ).then((okay) => {
        if (okay) {
          window.location.replace('/profile/doctor/edit');
        }
      });
    } else {
      setOpenModal(true);
    }
  };

  const handleLoadMore = async () => {
    setIsLoadingMore(true);
    const newPage = page + 1;
    setPage(newPage);
    await performSearch(search, newPage, false);
  };

  const handlePageChange = async (newPage: number) => {
    setPage(newPage);
    await performSearch(search, newPage, true);
  };

  return (
    <>
      <PageTitle kTitle="Pacientes" />
      <CardContent>
        <Box sx={{ py: 2 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <TextField
              size="small"
              variant="outlined"
              placeholder="Busca a un paciente"
              autoComplete="search"
              sx={{ width: { xs: '100%', md: '40%' } }}
              value={search}
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment sx={{ cursor: 'pointer' }} position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                inputProps: {
                  maxLength: 25,
                },
              }}
            />

            {user.roles[0] === 'Proveedor' && (
              <Button
                color="secondary"
                variant="contained"
                onClick={handleClickOpen}
              >
                NUEVO PACIENTE
              </Button>
            )}
            <CustomModal
              open={openModal}
              handleModalToggle={() => setOpenModal(false)}
              title="Añadir nuevo paciente"
            >
              <NewPatientModal />
            </CustomModal>
          </Stack>
          <Box sx={{ py: 2 }}>
            {isLoading && page === 0 ? (
              <LoadingBoxProgress
                styleBox={{
                  display: 'flex', justifyContent: 'center', py: 2, pt: '50px', height: '350px',
                }}
                colorLoading="primary"
              />
            ) : searchList && searchList.length > 0 ? (
              <>
                <TablePatientData
                  ListData={searchList}
                  totalCount={totalCount}
                  hasMore={
                    isMobile
                      ? searchList.length < totalCount
                      : (page + 1) * 5 < totalCount
                  }
                  page={page}
                  onSubmit={!isMobile ? handlePageChange : undefined}
                  onLoadMore={isMobile ? handleLoadMore : undefined}
                  isLoadingMore={isLoadingMore}
                  isMobile={isMobile}
                />
              </>
            ) : (
              <NoSearchResult text="No se encontraron resultados" />
            )}
          </Box>
        </Box>
      </CardContent>
    </>
  );
}
