/* eslint-disable import/no-unresolved */
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import {
  Avatar, Button, Card, CardContent,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import 'moment/locale/es';
import { getNextPendingResident, getNextPendingVisit } from '../../../redux/store/home';
import { homeCardState } from '../home.model';
import bgBlue from '../../../images/Blue800.png';
import bgPurple from '../../../images/Purple800.png';
import { getDetailVisit } from '../../../redux/store/visit';

function NextVisitData(props: homeCardState) {
  const {
    name, reason, visitDate, startHour, img,
  } = props;

  function formatDate(date: any, hour: any) {
    const formattedDay = moment(date).format('DD');
    const formattedMounth = moment(date).format('MMMM');
    const formattedHour = hour.substring(0, 5);
    return `${formattedDay} de ${formattedMounth} a las ${formattedHour} hrs.`;
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="flex-start"
      spacing={2}
      sx={{ width: '100%' }}
    >
      <Avatar
        src={img}
        alt={name}
        sx={{
          height: { sm: 60, xs: 40 },
          width: { sm: 60, xs: 40 },
        }}
      />
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        spacing={0}
      >
        <Typography
          color="white"
          variant="h5"
          sx={{ width: '100%', fontWeight: 'bold', fontSize: { xs: 16, sm: 24 } }}
        >
          {formatDate(visitDate, startHour)}
        </Typography>
        <Typography
          color="white"
          sx={{ width: '100%', fontWeight: 'regular', fontSize: { xs: 14, sm: 18 } }}
        >
          {reason != null ? `${name} - ${reason}` : name}
        </Typography>
      </Stack>
    </Stack>
  );
}

export default function HomeCard() {
  const dispatch = useDispatch();
  const history = useHistory();
  const nextPendingLoading = localStorage.getItem('nextPendingLoading');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const state = useSelector((GenState: any) => GenState);
  const {
    ui: { loading }, auth: user, home,
  } = state;
  const userRole = user.roles[0];

  const Styles = {
    textButtonColor: userRole != 'Proveedor' ? '#03A9DB' : '#936AD0',
    text: userRole != 'Proveedor' ? '¡Busca a un médico y programa tu próxima cita!'
      : '¡Programa tu próxima cita!',
  };

  useEffect(() => {
    if (user.userId != null) {
      switch (userRole) {
        case 'Proveedor':
          if (!loading && nextPendingLoading != 'true') {
            localStorage.setItem('nextPendingLoading', 'true');
            dispatch(getNextPendingResident(user.userId) as any);
          }
          break;
        case 'Subordinate':
          if (!loading && nextPendingLoading != 'true') {
            localStorage.setItem('nextPendingLoading', 'true');
            dispatch(getNextPendingResident(user.doctorProfileData.userId) as any);
          }
          break;
        default:
          if (!loading && nextPendingLoading != 'true') {
            localStorage.setItem('nextPendingLoading', 'true');
            dispatch(getNextPendingVisit(user.userId) as any);
          }
          break;
      }
    }
  }, [dispatch]);

  const handleSubmit = (value: any) => {
    if (home.visitDate != null) {
      dispatch(getDetailVisit(value.visitId) as any);
      history.push('/visit/detail');
    } else history.push('/visit/create');
  };

  return (
    <>
      {home.visitDate != null ? (
        <Typography variant="h6" component="h6" mb={1}>
          Tu próxima cita
        </Typography>
      ) : null}

      <Card sx={{
        backgroundImage: userRole == 'Proveedor' ? `url(${bgBlue})` : `url(${bgPurple})`,
        backgroundSize: 'cover',
        backgroundPosition: 'bottom',
        borderRadius: '8px',
      }}
      >
        <CardContent sx={{ p: 3 }}>
          <Stack
            direction={{ sm: 'row', xs: 'column' }}
            justifyContent="center"
            alignItems={{ sm: 'center', xs: 'flex-end' }}
            spacing={2}
            sx={{ minHeight: { sm: 110, xs: 90 } }}
          >
            {home.visitDate != null ? (
              <NextVisitData
                name={userRole == 'User' ? home.doctorName : home.userName}
                reason={home.reason}
                visitDate={home.visitDate}
                startHour={home.startHour}
                img={home.image}
              />
            ) : (
              <Typography
                color="white"
                align="left"
                variant="h5"
                sx={{ width: { sm: '70%', xs: '100%' }, fontWeight: 'bold', fontSize: { xs: 12, sm: 24 } }}
              >
                {Styles.text}
              </Typography>
            )}
            <Button
              onClick={() => handleSubmit(home)}
              size={isMobile ? 'small' : 'large'}
              sx={{
                whiteSpace: 'nowrap',
                px: 4,
                color: Styles.textButtonColor,
                backgroundColor: 'white',
                '&:hover': { backgroundColor: 'white' },
              }}
            >
              {home.visitDate != null ? 'VER' : 'CREAR CITA'}
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
}
