import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import {
  Grid, Stack, Container, Typography,
} from '@mui/material';
import { BasicTextModels } from './basicText.models';
import TvaeMuiTheme from '../../../theme/tvae-mui-theme';
import img1 from '../../../images/backcomercial.jpg';

export default function BasicText(props: BasicTextModels) {
  // const coverImage = [img1];

  // const randomCoverImage = coverImage[Math.floor(Math.random() * coverImage.length)];
  // const imgUnplash = 'https://images.unsplash.com/photo-1554435493-93422e8220c8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1636&q=80';

  return (
    <ThemeProvider theme={TvaeMuiTheme}>
      <CssBaseline />
      <Grid
        container
        component="main"
        sx={{
          height: '100vh', maxWidth: '100%', Width: '100%',
        }}
      >
        <Grid
          item
          xs={false}
          sm={false}
          md={6}
          lg={6}
          sx={{
            width: '100%',
            // height: '100%',
            backgroundImage: `url("${img1}")`,
            // backgroundImage: 'url("https://source.unsplash.com/random")',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
            backgroundSize: 'cover',
            backgroundPosition: 'right',
          }}
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            px={3}
            sx={{ width: '100%', height: '100%', background: 'rgb(0 0 0 / 50%)' }}
          >
            <Typography
              variant="h2"
              sx={{
                color: 'white', // Texto en blanco
                fontWeight: 'bold', // Texto en negrita
                textAlign: 'center', // Centrar el texto (opcional)
              }}
            >
              Preguntas
              <br />
              Frecuentes
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} component={Paper} elevation={0} square style={{ position: 'relative' }}>

          <Container
            component="main"
            maxWidth="md"
            sx={{
              width: '100%', maxHeight: '100vh', overflow: 'auto',
            }}
          >
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
              px={3}
              sx={{ width: '100%', height: '100%', pt: '2rem' }}
            >
              {props.kContent}
            </Stack>
          </Container>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
