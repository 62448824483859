import * as React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { onMessage } from 'firebase/messaging';
import { messaging } from './firebase';
import Login from './pages/login/login';
import Register from './pages/login/register';
import ForgotPassword from './pages/login/recovery';
import ResetPassword from './pages/login/resetPass';
import Home from './pages/home/home';
import Visit from './pages/visit/visit';
import CreateVisit from './pages/visit/createVisit';
import { PrivateRoute } from './components/commons/PrivateRoute';
import { PublicRoute } from './components/commons/PublicRoute';
import AdminTemplate from './components/templates/admin/admin';
import SideForm from './components/templates/SideForm/SideForm';
import Basic from './components/templates/basicText/basicText';
import Profile from './pages/profile/profile';
import DetailVisit from './pages/visit/detailVisit';
import EditVisit from './pages/visit/editVisit';
import Reviews from './pages/reviews/reviews';
import Search from './pages/profile/search';
import EditDoctorProfile from './pages/profile/src/profiles/editDoctorProfile';
import EditPatientProfile from './pages/profile/src/profiles/editPatientProfile';
import Departments from './pages/departments/departments';
import Settings from './pages/settings/settings';
import SelectDoctor from './pages/selectDoctor/selectDoctor';
import CreatedVisit from './pages/visit/createdVisit';
import Faq from './pages/faq/faq';

function RouterApp() {
  toastr.options = {
    preventDuplicates: true,
  };
  React.useEffect(() => {
    onMessage(messaging, (message) => {
      if (message.notification?.title == 'Recordatorio') {
        toastr.info(`${message.notification?.body}`, `${message.notification?.title}`);
      } else toastr.success(`${message.notification?.body}`, `${message.notification?.title}`);
    });
  }, []);

  return (
    <>
      <BrowserRouter>
        <Switch>
          <PublicRoute
            exact
            path={['/', '/login']}
            component={() => <SideForm kContent={<Login />} />}
          />
          <PublicRoute
            exact
            path="/faq"
            component={() => <Basic kContent={<Faq />} />}
          />
          <PublicRoute
            exact
            path="/register"
            component={() => <SideForm kContent={<Register />} />}
          />
          <PublicRoute
            exact
            path="/recovery"
            component={() => <SideForm kContent={<ForgotPassword />} />}
          />
          <PublicRoute
            exact
            path="/reset"
            component={() => <SideForm kContent={<ResetPassword />} />}
          />
          <PrivateRoute
            exact
            path="/home"
            component={() => <AdminTemplate kContent={<Home />} />}
          />
          <PrivateRoute
            exact
            path="/departments"
            component={() => <AdminTemplate kContent={<Departments />} />}
          />
          <PrivateRoute
            exact
            path="/visit"
            component={() => <AdminTemplate kContent={<Visit />} />}
          />
          <PrivateRoute
            exact
            path="/visit/create"
            component={() => <AdminTemplate kContent={<CreateVisit />} />}
          />
          <PrivateRoute
            exact
            path="/visit/created"
            component={() => <AdminTemplate kContent={<CreatedVisit />} />}
          />
          <PrivateRoute
            exact
            path="/visit/detail"
            component={() => <AdminTemplate kContent={<DetailVisit />} />}
          />
          <PrivateRoute
            exact
            path="/visit/edit"
            component={() => <AdminTemplate kContent={<EditVisit />} />}
          />
          <PrivateRoute
            exact
            path="/profile"
            component={() => <AdminTemplate kContent={<Profile />} />}
          />
          <PrivateRoute
            exact
            path="/profile/doctor/edit"
            component={() => <AdminTemplate kContent={<EditDoctorProfile />} />}
          />
          <PrivateRoute
            exact
            path="/profile/edit"
            component={() => <AdminTemplate kContent={<EditPatientProfile />} />}
          />
          <PrivateRoute
            exact
            path="/reviews"
            component={() => <AdminTemplate kContent={<Reviews />} />}
          />
          <PrivateRoute
            exact
            path="/search"
            component={() => <AdminTemplate kContent={<Search />} />}
          />
          <PrivateRoute
            exact
            path="/settings"
            component={() => <AdminTemplate kContent={<Settings />} />}
          />
          <PrivateRoute
            exact
            path="/selectDoctor"
            component={() => <AdminTemplate kContent={<SelectDoctor />} />}
          />
          <Route
            exact
            path="*"
            component={() => (<h1>Page not found</h1>)}
          />
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default RouterApp;
