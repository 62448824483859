/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CardContent } from '@mui/material';
import ListVisit from '../AppointmentList/listVisit';
import { PatientVisitList } from '../../../../redux/store/visit';
import PageTitle from '../../../../components/shared/PageTitle';
import { TabPanel } from '../../components/visitComponents';

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function PatientVisit() {
  const state = useSelector((GenState: any) => GenState);
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [values, setValues] = React.useState<any>({
    scheduledList: [],
    historyList: [],
  });

  const { auth: user } = state;

  useEffect(() => {
    const fetchData = async () => {
      if (user.userId != null) {
        const list: any = await PatientVisitList(user.userId, 1, 100, '');
        setValues((prev: any) => list || prev);
      }
    };

    fetchData().catch(console.error);
  }, [user.userId]);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <PageTitle kTitle="Citas" />
      <CardContent>
        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{
            style: { backgroundColor: '#03A9DB' },
          }}
        >
          <Tab
            label="PROGRAMADAS"
            sx={{ '&.Mui-selected': { color: '#03A9DB' } }}
            {...a11yProps(0)}
          />
          <Tab
            label="HISTORIAL"
            sx={{ '&.Mui-selected': { color: '#03A9DB' } }}
            {...a11yProps(1)}
          />
        </Tabs>
        <TabPanel value={value} index={0} dir={theme.direction}>
          <ListVisit index={value} visitList={values.scheduledList} historicList={values.historyList} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <ListVisit index={value} visitList={values.scheduledList} historicList={values.historyList} />
        </TabPanel>
      </CardContent>
    </>
  );
}
