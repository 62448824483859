import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LoginDto } from '@alexnoriega3/terravital_api/dist/models';
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import logo_celtara from '../../images/logo_celtara.png';
import { startLoginEmailPassword } from '../../redux/store/auth';

export default function Login() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { ui: { loading }, auth: { userId, roles } } = useSelector((state: any) => state);

  const [values, setValues] = React.useState<LoginDto>({
    email: '',
    password: '',
  });
  const [errors, setErrors] = React.useState<any>({
    email: false,
    password: false,
  });
  const [disableBtn, setDisableBtn] = React.useState(false);

  const isValidLogin = values.email != '' && values.password != '';

  useEffect(() => {
    if (userId && userId != '') {
      switch (roles[0]) {
        case 'Subordinate':
          history.push('/selectDoctor');
          break;

        default:
          history.push('/home');
          break;
      }
    }
  }, [userId]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setDisableBtn(true);
    if (isValidLogin) {
      dispatch(startLoginEmailPassword(values) as any).catch(setTimeout(() => setDisableBtn(false), 1000));
    } else {
      setDisableBtn(false);
      setErrors({
        email: values.email == '',
        password: values.password == '',
      });
      Swal.fire('Campos Requeridos', 'Completar los campos requeridos', 'warning');
    }
  };

  return (
    <>
      <img src={logo_celtara} alt="Logo" style={{ width: '70%' }} />
      <Typography component="h1" variant="h5" sx={{ mt: 4 }}>
        Iniciar sesión
      </Typography>
      <Typography component="h1" variant="body1" sx={{ mb: 4 }}>
        <Link
          color="primary"
          href="/register"
          variant="body1"
          underline="none"
          sx={{ fontWeight: '500' }}
        >
          Regístrate
        </Link>
        {' '}
        o inicia sesión para agendar tu cita.
      </Typography>

      <Box component="form" onSubmit={handleSubmit} noValidate>
        <TextField
          sx={{ mt: 0 }}
          margin="normal"
          fullWidth
          id="email"
          label="Correo electrónico"
          name="email"
          value={values.email}
          onChange={handleChange}
          error={errors.email}
          autoComplete="email"
          autoFocus
          inputProps={{ maxLength: 50 }}
        />
        <TextField
          margin="normal"
          fullWidth
          name="password"
          label="Contraseña"
          type="password"
          id="password"
          value={values.password}
          onChange={handleChange}
          error={errors.password}
          autoComplete="current-password"
          inputProps={{ maxLength: 24 }}
        />
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          {!loading
            ? (
              <Link
                color="primary"
                href="/recovery"
                variant="body2"
                underline="none"
                sx={{ color: 'primary', mb: 4 }}
              >
                Olvidé mi contraseña
              </Link>
            ) : null}
        </Stack>
        <Button
          disabled={disableBtn}
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mb: 1 }}
        >
          INICIAR SESIÓN
        </Button>
        <Button
          disabled={disableBtn}
          fullWidth
          variant="outlined"
          href="/register"
        >
          CREAR CUENTA
        </Button>
      </Box>
    </>
  );
}
