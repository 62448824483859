import * as React from 'react';
import {
  Avatar, Box, Button, Card, List,
  ListItem, ListItemAvatar, ListItemButton,
  ListItemIcon, ListItemText, Radio,
  Stack, TablePagination, Typography,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { doctorListDTO, DoctorListProps } from '../../visit.model.d';
import { DoctorSchedule } from '../../../../redux/store/profile';
import CustomModal from '../../../../components/shared/Modal';
import DoctorProfile from '../../../profile/src/profiles/doctorProfile';

export default function DoctorList(props: DoctorListProps) {
  const {
    ListData, totalCount, checkedList, onSubmit, Pagination,
  } = props;

  const checked = checkedList;
  const [openModal, setOpenModal] = React.useState(false);
  const [idProfile, setIdProfile] = React.useState<any>();
  const [page, setPage] = React.useState(0);

  const handleChangePage = (_event: any, newPage: number) => {
    Pagination(newPage + 1);
    setPage(newPage);
  };

  const handleToggle = (providerId: string) => async () => {
    const result = await DoctorSchedule(providerId);
    if (providerId === checked) {
      onSubmit('', []);
    } else if (result != null) {
      onSubmit(providerId, result);
    } else {
      onSubmit('', []);
    }
  };

  const handleClickOpen = (providerId: string) => () => {
    setIdProfile(providerId);
    setOpenModal(true);
  };

  return (
    <>
      <Box sx={{ pb: 3 }}>
        <List>
          <Stack spacing={2}>
            {ListData.map((list: doctorListDTO) => {
              const labelId = `checkbox-list-label-${list.id}`;
              const department = list.department[list.department.length - 1]?.value || 'General';

              return (
                <Card key={list.id} variant="outlined" sx={{ borderRadius: '8px' }}>
                  <ListItem
                    secondaryAction={
                      (
                        <Button variant="text" color="primary" onClick={handleClickOpen(list.id)}>
                          Ver perfil
                        </Button>
                      )
                    }
                    disablePadding
                  >
                    <ListItemButton onClick={handleToggle(list.id)} dense>
                      <ListItemIcon sx={{ minWidth: 32 }}>
                        <Radio
                          edge="start"
                          checked={checked === list.id}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                          color="primary"
                        />
                      </ListItemIcon>
                      <ListItemAvatar sx={{ minWidth: 42 }}>
                        <Avatar alt={list.firstName} src={list.picture || list.image} sx={{ width: 32, height: 32 }} />
                      </ListItemAvatar>
                      <ListItemText
                        id={labelId}
                        primary={
                          (
                            <Typography
                              variant="body2"
                              sx={{
                                maxWidth: '130px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',
                              }}
                            >
                              {list.name}
                            </Typography>
                          )
                        }
                        secondary={
                          (
                            <>
                              {`${department} - ${list.rating != null ? parseFloat(list.rating).toFixed(1) : '5.0'}`}
                              <StarIcon color="secondary" sx={{ fontSize: 10, marginLeft: 1 }} />
                            </>
                          )
                        }

                      />
                    </ListItemButton>
                  </ListItem>
                </Card>
              );
            })}
          </Stack>
        </List>
        {totalCount > 5 && (
          <TablePagination
            component="div"
            count={totalCount}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={5}
            rowsPerPageOptions={[5]}
            labelRowsPerPage=""
          />
        )}
        <CustomModal
          handleModalToggle={() => setOpenModal(false)}
          open={openModal}
        >
          <DoctorProfile userId={idProfile} private={false} showButtons={false} />
        </CustomModal>
      </Box>
    </>
  );
}
