/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import * as React from 'react';
import {
  Avatar, Box, Button,
  CircularProgress,
  List, ListItem, ListItemAvatar, ListItemText,
  Stack, Table, TableBody,
  TableCell, TableContainer, TableHead, TablePagination, TableRow,
  Typography,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { TableDoctorDataProps } from '../../profile.model.d';
import CustomModal from '../../../../components/shared/Modal';
import DoctorProfile from '../profiles/doctorProfile';

export default function TableDoctorData(props: TableDoctorDataProps) {
  const {
    ListData, hasMore, totalCount, onSubmit, onLoadMore, isMobile, isLoadingMore, page,
  } = props;
  const [openModal, setOpenModal] = React.useState(false);
  const [idProfile, setIdProfile] = React.useState<any>();

  const handleChangePage = (_event: any, newPage: number) => {
    if (onSubmit) {
      onSubmit(newPage);
    }
  };

  const handleClick = (userId: string) => () => {
    setIdProfile(userId);
    setOpenModal(true);
  };

  return (
    <>
      {!isMobile && (
        <Box sx={{ py: 2 }}>
          <TableContainer>
            <Table sx={{ whiteSpace: 'nowrap' }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Especialidad</TableCell>
                  <TableCell>Rating</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {ListData.map((list) => {
                  const department = list.department[list.department.length - 1]?.value || 'Médico General';
                  return (
                    <TableRow
                      key={list.id}
                      sx={{ th: { border: 0, whiteSpace: 'nowrap' } }}
                    >
                      <TableCell component="th" scope="row">
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Avatar src={list.url} sx={{ width: 32, height: 32 }} alt={list.firstName} />
                          <Typography variant="body2" sx={{ maxWidth: '130px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {list.name}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell component="th" scope="row">{department}</TableCell>
                      <TableCell component="th" scope="row">
                        <Stack direction="row" spacing={1}>
                          <Typography variant="body2">
                            {list.rating != null ? parseFloat(list.rating).toFixed(1) : '5.0'}
                          </Typography>
                          <StarIcon sx={{ fontSize: 18, color: '#936AD0' }} />
                        </Stack>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Button
                          color="primary"
                          variant="text"
                          size="small"
                          onClick={handleClick(list.id)}
                        >
                          Ver Perfil
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {totalCount > 5 && (
              <TablePagination
                component="div"
                count={totalCount}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={5}
                rowsPerPageOptions={[5]}
                labelRowsPerPage=""
                nextIconButtonProps={{
                  disabled: (page + 1) * 5 >= totalCount,
                }}
              />
            )}
          </TableContainer>
        </Box>
      )}
      {isMobile && (
        <Box sx={{ py: 1, marginLeft: 1 }}>
          <List
            sx={{
              width: '100%',
              bgcolor: 'background.paper',
              position: 'relative',
              overflow: 'auto',
              height: '100%',
              '& ul': { padding: 0 },
            }}
          >
            <Stack spacing={2}>
              {ListData.map((list: any) => {
                const department = list.department[list.department.length - 1]?.value || 'General';
                return (
                  <ListItem
                    key={list.id}
                    secondaryAction={(
                      <Button
                        color="primary"
                        variant="text"
                        size="small"
                        onClick={handleClick(list.id)}
                      >
                        Ver Perfil
                      </Button>
                    )}
                    disablePadding
                  >
                    <ListItemAvatar>
                      <Avatar alt={`${list.firstName}`} src={`${list.url}`} />
                    </ListItemAvatar>
                    <ListItemText
                      id={list.id}
                      primary={(
                        <Box
                          sx={{
                            maxWidth: '50%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {list.firstName}
                          {list.lastName}
                        </Box>
                      )}
                      secondary={(
                        <>
                          {`${department} - ${list.rating != null ? parseFloat(list.rating).toFixed(1) : '5.0'}`}
                          <StarIcon sx={{ fontSize: 10, marginLeft: 1, color: '#936AD0' }} />
                        </>
                      )}
                    />
                  </ListItem>
                );
              })}
            </Stack>
          </List>
          {hasMore && onLoadMore && (
            <Box sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
              {isLoadingMore ? (
                <CircularProgress color="primary" />
              ) : (
                <Button variant="contained" onClick={onLoadMore}>
                  Ver más
                </Button>
              )}
            </Box>
          )}
        </Box>
      )}
      <CustomModal
        handleModalToggle={() => setOpenModal(false)}
        open={openModal}
      >
        <DoctorProfile userId={idProfile} private={false} showButtons />
      </CustomModal>
    </>
  );
}
