/* eslint-disable no-shadow */
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AppBar, Badge, Box, Container, IconButton, Popover, Stack,
  Toolbar, Tooltip, SwipeableDrawer, Button, Drawer, useTheme, useMediaQuery, Link,
  Avatar,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LogoutIcon from '@mui/icons-material/Logout';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { browserName } from 'react-device-detect';
import celtara from '../../images/iconLogoWhite.png';
import logo_celtara from '../../images/logoWhite.png';
import { startLogout } from '../../redux/store/auth';
import ReviewModal from '../../pages/reviews/reviewModal';
import { GetMissingReview, CreateReview } from '../../redux/store/profile';
import SideBarMenu from './Menu';
import NotificationsPaper from './notifications';
import {
  DesactivePushNotif, IgnoreNotification, Notifications, ViewedNotification,
} from '../../redux/store/settings';
import { getDetailVisit } from '../../redux/store/visit';

const MenuAppBar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const history = useHistory();
  const dispatch = useDispatch();
  const {
    auth: {
      userId, name, firstName, image, rating, roles, doctorProfileData,
    },
    ui: { loading },
    settings: { notifications },
  } = useSelector((GenState: any) => GenState);
  const doctorId = userId;
  const doctorData = {
    name,
    picture: image,
    rating: rating || '5.0',
  };
  const loadNotifications = localStorage.getItem('loadNotifications');
  const loadReview = localStorage.getItem('loadReview');
  const pushStatus = localStorage.getItem('pushStatus');

  React.useEffect(() => {
    if (userId != null) {
      if (!loading && loadNotifications != 'true') {
        localStorage.setItem('loadNotifications', 'true');
        dispatch(Notifications(userId) as any);
      }
      if (loadReview != 'true' && roles[0] != 'Proveedor' && roles[0] != 'Subordinate') {
        setTimeout(() => {
          localStorage.setItem('loadReview', 'true');
          const fetchData = async () => {
            const result = await GetMissingReview();
            if (result != null) {
              setReviewData(result);
              setOpenReviewModal(true);
            }
          };
          fetchData().catch(console.error);
        }, 1000);
      }
    }
  }, []);

  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [anchorElPopover, setAnchorElPopover] = React.useState<null | HTMLElement>(null);
  const [openNotifications, setOpenNotifications] = React.useState(false);
  const [openReviewModal, setOpenReviewModal] = React.useState(false);
  const [reviewData, setReviewData] = React.useState<any>({
    visitId: '',
    id: '',
    image: '',
    firstName: '',
    lastName: '',
    titleAbbreviation: '',
  });
  const [notif, setNotifications] = React.useState<any[]>(notifications);

  let totalUnRead: any = 0;

  totalUnRead = notif.filter((item) => item.isRead === false && item.ignore === false).length;
  const date = notif.map((value) => (moment(value.createDate, ['YYYY-MM-DD']).format('YYYY-MM-DD')));
  const subheaderDate = [...new Set(date)];

  const logOut = async () => {
    localStorage.clear();
    if (pushStatus != 'true') {
      window.location.replace('/login');
      dispatch(startLogout() as any);
    } else {
      const data = {
        deviceId: '',
        tokenDevice: '',
        deviceName: `${firstName.toLowerCase()}Device`,
        subcribed: 'False',
        active: false,
        platForm: 'Web',
        identifier: `${browserName}_${firstName}`,
      };

      const result = await DesactivePushNotif(data);
      if (result != null) {
        window.location.replace('/login');
        dispatch(startLogout() as any);
      }
    }
  };

  // Open notifications web
  const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorElPopover(event.currentTarget);
  const handleClose = () => setAnchorElPopover(null);

  // Open notifications responsive mobile
  const handleClickOpen = () => setOpenNotifications(true);
  const handleCloseNotifications = () => setOpenNotifications(false);

  const handleDelete = async (notification: any) => {
    setNotifications((notifications) => notifications.filter((value) => value.notificationId !== notification.notificationId));
    const result = await IgnoreNotification(notification.notificationId);
    if (result != null) {
      localStorage.setItem('loadNotifications', 'false');
    }
  };

  const handleOpenNotification = async (notification: any) => {
    let newArr = [];
    newArr = notif.map((item: any) => {
      if (notification.notificationId == item.notificationId) return { ...item, isRead: true };
      return item;
    });
    setNotifications(newArr);
    totalUnRead = notif.filter((item: any) => item.isRead === false).length;
    const result = await ViewedNotification(notification.notificationId);
    if (result != null) {
      localStorage.setItem('loadNotifications', 'false');
      if (notification.entityRelated != 'Review') {
        dispatch(getDetailVisit(notification.relationId) as any);
        history.push('/visit/detail');
      } else {
        localStorage.setItem('reviewsLoading', 'false');
        history.push('/reviews', { doctorId, doctorData });
      }
    }
  };

  const handleCloseReviewModal = (newValue?: any) => {
    setOpenReviewModal(false);

    if (newValue) {
      const data = {
        ratingId: '',
        userId,
        doctorId: reviewData.id,
        visitId: reviewData.visitId,
        score: newValue.score,
        comments: newValue.comments,
      };

      dispatch(CreateReview(data) as any);
    }
  };

  const open = Boolean(anchorElPopover);
  const id = open ? 'simple-popover' : undefined;
  return (
    <AppBar
      component="nav"
      elevation={0}
      color={roles[0] == 'User' ? 'primary' : 'secondary'}
      sx={{ borderBottom: (t) => `2px solid ${t.palette.divider}` }}
    >
      <Toolbar>
        <Container
          maxWidth="lg"
          sx={{ p: 0 }}
        >
          {userId ? (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              p={0}
            >
              <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={() => setOpenDrawer(!openDrawer)}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Drawer
                  anchor="left"
                  variant="temporary"
                  sx={{ minWidth: 260, '.MuiList-root': { minWidth: 260 } }}
                  open={openDrawer}
                  onClose={() => setOpenDrawer(false)}
                >
                  <Toolbar />
                  <SideBarMenu />
                </Drawer>
              </Box>
              <Link
                href="/home"
                sx={{ display: { xs: 'inline-flex', md: 'none' } }}
              >
                <img src={celtara} width="auto" height="40" alt="Logo" />
              </Link>
              <Link
                href="/home"
                sx={{ display: { xs: 'none', md: 'inline-flex' } }}
              >
                <img src={logo_celtara} width="auto" height="40" alt="Logo" />
              </Link>

              <Box sx={{ flexGrow: 0 }}>
                <Stack direction="row" spacing={2}>
                  {(roles[0] == 'Subordinate' && history.location.pathname != '/selectDoctor') && (
                    <Tooltip title="Doctor" sx={{ display: { md: 'flex', xs: 'none' } }}>
                      <>
                        <Avatar alt={doctorProfileData.name} src={doctorProfileData.url} />
                        <Typography
                          color="textPrimary"
                          variant="subtitle1"
                          sx={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            lineHeight: 2.5,
                          }}
                        >
                          {doctorProfileData.name}
                        </Typography>
                      </>
                    </Tooltip>
                  )}
                  <Tooltip title="Abrir Notificaciones">
                    <IconButton color="inherit" onClick={(event) => (isMobile ? handleClickOpen() : handleClick(event))}>
                      <Badge badgeContent={totalUnRead} color="error">
                        <NotificationsIcon />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorElPopover}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <NotificationsPaper
                      subheaderDate={subheaderDate}
                      notifications={notif}
                      openNotification={(list) => handleOpenNotification(list)}
                      deleteNotification={(list) => handleDelete(list)}
                    />
                  </Popover>
                  <Tooltip title="Cerrar sesión" sx={{ display: { md: 'flex', xs: 'none' } }}>
                    <Button onClick={() => logOut()} color="inherit" startIcon={<LogoutIcon />}>
                      Cerrar sesión
                    </Button>
                  </Tooltip>
                </Stack>
              </Box>
            </Stack>
          ) : null}
        </Container>
      </Toolbar>
      <SwipeableDrawer
        anchor="bottom"
        open={openNotifications}
        onClose={handleCloseNotifications}
        onOpen={handleClickOpen}
        swipeAreaWidth={44}
        disableSwipeToOpen
        sx={{
          display: { xs: 'block', md: 'none' },
          borderTopLeftRadius: 9,
          borderTopRightRadius: 9,
        }}
      >
        <Box
          sx={{
            px: 2,
            pb: 2,
            height: '100%',
            overflow: 'auto',
          }}
        >
          <NotificationsPaper
            subheaderDate={subheaderDate}
            notifications={notif}
            openNotification={(list) => handleOpenNotification(list)}
            deleteNotification={(list) => handleDelete(list)}
          />
        </Box>
      </SwipeableDrawer>
      <ReviewModal
        data={reviewData}
        keepMounted
        open={openReviewModal}
        onClose={handleCloseReviewModal}
      />
    </AppBar>
  );
};

export default MenuAppBar;
