import * as React from 'react';
import {
  Button, Stack, Grid, CardContent, CardActions, Divider,
  Container, Box, Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import DoctorCard from '../../utils/doctorCard';
import GeneralInfomation from './src/genInformation';
import PatientCard from '../../utils/patientCard';
import { DoctorSchedule } from '../../redux/store/profile';
import PageTitle from '../../components/shared/PageTitle';
import QR from '../../utils/QR';
import { DetailComments, DetailTextInfo, DetailTitles } from './components/visitComponents';
import { LoadingStackProgress } from '../../utils/loadingsFormats';
import { getDetailVisit } from '../../redux/store/visit';

export default function CreatedVisit() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { state }: any = history.location;
  const { visitId }: any = state ?? { visitId: '' };
  const State = useSelector((GenState: any) => GenState);
  const [loadPage, setLoadPage] = React.useState(true);
  const [doctorSchedule, setDoctorSchedules] = React.useState<any>({});

  const { auth: user, visit: detailVisit } = State;
  const userRole = user.roles[0];

  React.useEffect(() => {
    if (visitId != detailVisit.visitId) {
      dispatch(getDetailVisit(visitId) as any);
    }
  }, [visitId]);

  React.useEffect(() => {
    if (visitId == detailVisit.visitId) {
      const fetchData = async () => {
        const result = await DoctorSchedule(detailVisit.doctorId);
        if (result != null) {
          setDoctorSchedules(result);
        }
      };
      fetchData().catch(console.error);
      setTimeout(() => {
        setLoadPage(false);
      }, 1800);
    }
  }, [detailVisit, visitId]);

  const NewVisit = () => history.push('/visit/create');

  const handleClick = async () => history.push('/visit');

  return (
    <>
      <PageTitle
        kTitle="Detalle de cita"
      />
      {loadPage ? (
        <LoadingStackProgress color="secondary" />
      ) : (
        <>
          <CardContent>
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={1} sx={{ my: 2 }}>
              <CheckCircleOutlineOutlinedIcon sx={{ fontSize: 30, color: '#6AD087' }} />
              <Typography variant="h5" align="center" sx={{ fontWeight: 600 }}>
                ¡Tu cita ha sido generada exitosamente!
              </Typography>
            </Stack>
            <Grid
              container
              direction={{ sm: 'row', xs: 'column-reverse' }}
              sx={{ py: 4 }}
              justifyContent="center"
            >
              {detailVisit.cancelled == 'Ninguno' && (
              <Grid item xs={12} sm={5} lg={6} sx={{ textAlign: 'center' }}>
                <Container maxWidth="xs">
                  <QR qr={detailVisit.qr} toleration={detailVisit.entryToleranceTime} />
                </Container>
              </Grid>
              )}
              <Grid item xs={12} sm={7} lg={6}>
                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                  {userRole == 'User' ? (
                    <DoctorCard
                      doctorId={detailVisit.doctorId}
                      name={detailVisit.doctorName}
                      picture={detailVisit.doctorImage}
                      department={doctorSchedule?.department}
                      local={detailVisit.localName}
                      rating={detailVisit.rating != null ? detailVisit.rating : '5.0'}
                      align="flex-start"
                    />
                  ) : (
                    <PatientCard
                      name={detailVisit.userName}
                      email={detailVisit.userEmail}
                      picture={detailVisit.userImage}
                      align="flex-start"
                    />
                  )}
                </Box>
                <Container maxWidth="xs">
                  <GeneralInfomation
                    doctorName={detailVisit.doctorName}
                    date={detailVisit.visitDate}
                    hour={detailVisit.startHour}
                    reason={detailVisit.reason}
                    role={userRole}
                    levelLocal={detailVisit.localName}
                    localAddress={doctorSchedule?.address}
                    urlMap={detailVisit.urlMap}
                  />
                  <DetailTitles text="COMENTARIOS" style={{ fontWeight: 600, mt: 3 }} />
                  <DetailComments text={detailVisit.comments} />
                  <DetailTitles text="COSTO" style={{ fontWeight: 600, mt: 3 }} />
                  <DetailTextInfo
                    text={`$ ${detailVisit.costPerAppointment} MXN`}
                    variant="subtitle1"
                    style={{ wordWrap: 'break-word', overflowWrap: 'anywhere', mb: { xs: 2 } }}
                  />
                </Container>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Stack
              direction={{ sm: 'row', xs: 'column-reverse' }}
              justifyContent="flex-end"
              alignItems="center"
              spacing={1}
              p={2}
              sx={{ width: '100%' }}
            >
              <Button
                variant="outlined"
                onClick={(handleClick)}
                color={userRole == 'User' ? 'primary' : 'secondary'}
                sx={{ width: { sm: '20%', xs: '100%' } }}
              >
                IR A CITAS
              </Button>
              <Button
                variant="contained"
                onClick={(NewVisit)}
                color={userRole == 'User' ? 'primary' : 'secondary'}
                sx={{ width: { sm: '20%', xs: '100%' } }}
              >
                CREAR CITA
              </Button>
            </Stack>
          </CardActions>
        </>
      )}
    </>
  );
}
