import { isValidEmail, isValidPassword } from '../../../utils/Validate';

export default function validateUserRegister(data: any) {
  const fieldsUser: any = ['firstName', 'lastName', 'email', 'password'];
  const missingFields: any = [];
  let showAlert = false;
  fieldsUser.forEach((field: any) => {
    switch (field) {
      case 'email':
        const validEmail = isValidEmail(data.email);
        if (data.email == '' || !validEmail) {
          missingFields.push({
            errorField: 'email',
            errorText: (validEmail && data.email != '') ? 'Formato de Correo Incorrecto' : 'Correo electrónico requerido.',
          });
        }
        break;
      case 'password':
        const responsePass = isValidPassword(data.password);
        if (data.password == '' || !responsePass.isValid) {
          missingFields.push({
            errorField: 'password',
            errorText: (!responsePass.isValid && data.password != '') ? responsePass.messageError : 'Contraseña requerida.',
          });
        }
        break;
      default:
        if (data?.[field] === '' || !/^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ.'\-\s_]+$/.test(data?.[field])) {
          missingFields.push({
            errorField: field,
            errorText: data?.[field] === '' ? ''
              : `${field == 'firstName' ? 'Nombre:' : 'Apellido:'} Usar solo letras, espacios, puntos, incluyendo acentos y diéresis.`,
          });
          showAlert = data?.[field] != '';
        }
        break;
    }
  });

  return { isValid: missingFields.length > 0, missingFields, showAlert };
}
