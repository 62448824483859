/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import {
  Button, Stack, Grid, CardContent, CardActions, Divider,
  Container, Alert, Box, SwipeableDrawer, useTheme, useMediaQuery,
  // Link,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Swal from 'sweetalert2';
import DoctorCard from '../../utils/doctorCard';
import GeneralInfomation from './src/genInformation';
import PatientCard from '../../utils/patientCard';
import { DoctorSchedule } from '../../redux/store/profile';
import PageTitle from '../../components/shared/PageTitle';
import QR from '../../utils/QR';
import CustomModal from '../../components/shared/Modal';
import CancelModal from './src/cancelModal';
import { completeVisit, cancelVisit } from '../../redux/store/visit/thunks';
import { Cancelled, canceledBy, disableEditVisit } from '../../utils/Validate';
import CompleteProcessModal from './src/completeProcessModal';
import { DetailComments, DetailTextInfo, DetailTitles } from './components/visitComponents';
import { LoadingStackProgress } from '../../utils/loadingsFormats';

export default function DetailVisit() {
  const dispatch = useDispatch();
  const history = useHistory();
  const State = useSelector((GenState: any) => GenState);
  const [loadPage, setLoadPage] = React.useState(true);
  const [openCancelModal, setOpenCancelModal] = React.useState(false);
  const [openCancelDrawer, setOpenCancelDrawer] = React.useState(false);
  const [disabledBtn, setDisabledBtn] = React.useState(true);
  const [doctorSchedule, setDoctorSchedules] = React.useState<any>({
    address: '',
    department: '',
    levelLocal: [''],
    schedules: [''],
    busyDates: [''],
    datesAvailable: [''],
  });

  const { auth: user, visit: detailVisit } = State;
  const userRole = user.roles[0];

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  React.useEffect(() => {
    const fetchData = async () => {
      const result = await DoctorSchedule(detailVisit.doctorId);
      if (result != null) {
        setDoctorSchedules(result);
      }
    };
    fetchData().catch(console.error);
    setTimeout(() => {
      setLoadPage(false);
      setDisabledBtn(disableEditVisit(detailVisit.visitStatus, detailVisit));
    }, 1800);
  }, []);

  const handleOpenModal = () => {
    if (disableEditVisit(detailVisit.visitStatus, detailVisit)) {
      setDisabledBtn(true);
      Swal.fire('Se ha agotado el tiempo para editar su cita', '', 'info');
    } else if (isMobile) {
      setOpenCancelDrawer(true);
    } else setOpenCancelModal(true);
  };

  const handleCloseModal = () => {
    if (isMobile) {
      setOpenCancelDrawer(false);
    } else setOpenCancelModal(false);
  };

  const handleClick = async () => {
    const visitDetail = detailVisit;
    if (disableEditVisit(detailVisit.visitStatus, detailVisit)) {
      setDisabledBtn(true);
      Swal.fire('Se ha agotado el tiempo para editar su cita', '', 'info');
    } else {
      history.push('/visit/edit', {
        visitDetail,
        doctorSchedule,
      });
    }
  };

  const handleCancel = (cancelOption: string, other: string) => {
    const visitData = {
      visitId: detailVisit.visitId,
      localId: detailVisit.localId,
      userId: detailVisit.userId,
      providerId: detailVisit.doctorId,
      reason: detailVisit.reason,
      comments: detailVisit.comments,
      cancelled: cancelOption,
      cancelOther: other,
      visitDate: detailVisit.visitDate,
      startHour: detailVisit.startHour,
      endHour: detailVisit.endHour,
      visitStatusId: '',
    };
    dispatch(cancelVisit(visitData) as any);
  };

  const handleCompleteVisit = () => {
    dispatch(completeVisit(detailVisit.visitId) as any);
  };

  const goToNewVisit = async () => {
    const doctorData = {
      doctorId: detailVisit.doctorId,
      name: detailVisit.doctorName,
      picture: detailVisit.doctorImage,
      department: doctorSchedule?.department,
      rating: detailVisit.rating != null ? detailVisit.rating : '5.0',
    };
    const patientData = {
      userId: detailVisit.userId,
      name: detailVisit.userName,
      image: detailVisit.userImage,
      phone: detailVisit.userCel,
      email: detailVisit.userEmail,
    };

    if (doctorSchedule != null) {
      history.push('/visit/create', {
        doctorData,
        patientData,
        doctorSchedule,
      });
    }
  };

  return (
    <>
      <PageTitle
        kTitle="Detalle de cita"
        kLeft={(
          <Button onClick={() => history.goBack()} color={userRole != 'Proveedor' ? 'primary' : 'secondary'}>
            <ArrowBackIosIcon />
          </Button>
        )}
      />
      {loadPage == true ? (
        <LoadingStackProgress color="secondary" />
      ) : (
        <>
          {detailVisit.visitStatus == 'Cancelada' && (
            <>
              <Alert severity="error" icon={<NotInterestedIcon fontSize="inherit" />} sx={{ borderRadius: 0 }}>
                <b>
                  {`Tu cita ha sido cancelada, ${detailVisit.userCancel ? 'cancelaste la cita debido a:' : `${canceledBy(userRole, detailVisit)} cancelo debido a:`}`}
                </b>
                <span>{` ${detailVisit.cancelled != 'Otro' ? Cancelled(detailVisit.cancelled) : detailVisit.cancelOther}`}</span>
              </Alert>
            </>
          )}
          {detailVisit.visitStatus == 'Completada' && (
            <Alert severity="success" icon={<CheckCircleIcon fontSize="inherit" />} sx={{ borderRadius: 0 }}>
              <b>Cita completada </b>
            </Alert>
          )}
          <CardContent>
            <Grid
              container
              direction={{ sm: 'row', xs: 'column-reverse' }}
              sx={{ py: 4 }}
              justifyContent="center"
            >
              {detailVisit.cancelled == 'Ninguno' && (
              <Grid item xs={12} sm={5} lg={6} sx={{ textAlign: 'center' }}>
                <Container maxWidth="xs">
                  <QR qr={detailVisit.qr} toleration={detailVisit.entryToleranceTime} />
                </Container>
              </Grid>
              )}
              <Grid item xs={12} sm={7} lg={6}>
                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                  {userRole == 'User' ? (
                    <DoctorCard
                      doctorId={detailVisit.doctorId}
                      name={detailVisit.doctorName}
                      picture={detailVisit.doctorImage}
                      department={doctorSchedule.department}
                      local={detailVisit.localName}
                      rating={detailVisit.rating != null ? detailVisit.rating : '5.0'}
                      align="flex-start"
                    />
                  ) : (
                    <PatientCard
                      name={detailVisit.userName}
                      email={detailVisit.userEmail}
                      picture={detailVisit.userImage}
                      align="flex-start"
                    />
                  )}
                </Box>
                <Container maxWidth="xs">
                  <GeneralInfomation
                    doctorName={detailVisit.doctorName}
                    date={detailVisit.visitDate}
                    hour={detailVisit.startHour}
                    reason={detailVisit.reason}
                    role={userRole}
                    levelLocal={detailVisit.localName}
                    localAddress={doctorSchedule.address}
                    urlMap={detailVisit.urlMap}
                  />
                  {/* <Link
                    component="button"
                    variant="body2"
                    sx={{ fontWeight: 600, mt: 2 }}
                    onClick={() => window.open(detailVisit.urlMap, '_blank', 'noreferrer')}
                  >
                    Ver Mapa
                  </Link> */}
                  <DetailTitles text="COMENTARIOS" style={{ fontWeight: 600, mt: 3 }} />
                  <DetailComments text={detailVisit.comments} />
                  <DetailTitles text="COSTO" style={{ fontWeight: 600, mt: 3 }} />
                  <DetailTextInfo
                    text={`$ ${detailVisit.costPerAppointment} MXN`}
                    variant="subtitle1"
                    style={{ wordWrap: 'break-word', overflowWrap: 'anywhere', mb: { xs: 2 } }}
                  />
                </Container>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Stack
              direction={{ sm: 'row', xs: 'column-reverse' }}
              justifyContent="flex-end"
              alignItems="center"
              spacing={1}
              p={2}
              sx={{ width: '100%' }}
            >
              {detailVisit.visitStatus == 'Pendiente' && userRole == 'Proveedor' && (
                <CompleteProcessModal
                  dateVisit={detailVisit.visitDate}
                  onSubmit={(complete: any) => { if (complete) handleCompleteVisit(); }}
                />
              )}
              {(detailVisit.visitStatus == 'Pendiente') ? (
                <>
                  {userRole !== 'Subordinate' && (
                    <Button
                      disabled={disabledBtn}
                      variant="outlined"
                      onClick={handleOpenModal}
                      color={userRole == 'User' ? 'primary' : 'secondary'}
                      sx={{ width: { sm: '20%', xs: '100%' } }}
                    >
                      CANCELAR CITA
                    </Button>
                  )}
                  <CustomModal
                    open={openCancelModal}
                    handleModalToggle={() => setOpenCancelModal(false)}
                  >
                    <CancelModal
                      role={userRole}
                      onSubmit={(Cancel, Other) => handleCancel(Cancel, Other)}
                    />
                  </CustomModal>
                  <SwipeableDrawer
                    anchor="bottom"
                    open={openCancelDrawer}
                    onClose={handleCloseModal}
                    onOpen={handleOpenModal}
                    swipeAreaWidth={44}
                    disableSwipeToOpen
                    sx={{
                      display: { xs: 'block', md: 'none' },
                      '& .MuiDrawer-paper': {
                        borderTopLeftRadius: 9,
                        borderTopRightRadius: 9,
                      },
                    }}
                  >
                    <Box p={2}>
                      <CancelModal
                        role={userRole}
                        onSubmit={(Cancel, Other) => {
                          handleCancel(Cancel, Other);
                        }}
                      />
                    </Box>
                  </SwipeableDrawer>
                  <Button
                    disabled={disabledBtn}
                    variant="contained"
                    onClick={(handleClick)}
                    color={userRole == 'User' ? 'primary' : 'secondary'}
                    sx={{ width: { sm: '20%', xs: '100%' } }}
                  >
                    EDITAR
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    variant="contained"
                    onClick={(goToNewVisit)}
                    color={userRole == 'User' ? 'primary' : 'secondary'}
                    sx={{ width: { sm: '25%', xs: '100%' } }}
                  >
                    REPROGRAMAR CITA
                  </Button>
                </>
              )}
            </Stack>
          </CardActions>
        </>
      )}
    </>
  );
}
