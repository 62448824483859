/* eslint-disable no-unused-vars */
import {
  Avatar, Box, Typography, List, ListSubheader, ListItem, IconButton, ListItemAvatar, ListItemText, ListItemButton,
} from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import moment from 'moment';

export interface NotificationsModels {
  subheaderDate?: any;
  notifications?: any;
  openNotification(list: any): void;
  deleteNotification(list: any): void;
}

export default function NotificationsPaper(props: NotificationsModels) {
  const {
    subheaderDate, notifications, openNotification, deleteNotification,
  } = props;

  function isSameDate(notifDate: any, createVisitDate: any) {
    const formattedND = moment(notifDate);
    const formattedCVD = moment(createVisitDate);

    return formattedND.isSame(formattedCVD, 'day');
  }

  return (
    <Box sx={{ width: { md: '400px', xs: '100%' }, pb: '50px' }}>
      <Typography variant="body1" gutterBottom component="div" sx={{ p: 2, pb: 0 }}>
        Notificaciones
      </Typography>
      <List sx={{ mb: 1 }}>
        {subheaderDate.map((subheader: any) => (
          <>
            <ListSubheader key={subheader}>{moment(subheader, ['YYYY-MM-DD']).format('dd/MM/yyyy')}</ListSubheader>
            {notifications.map((list: any) => (
              <>
                {isSameDate(subheader, list.createDate) ? (
                  <ListItem
                    key={list.notificationId}
                    secondaryAction={(
                      <IconButton onClick={() => deleteNotification(list)}>
                        <DeleteOutlineOutlinedIcon color="primary" />
                      </IconButton>
                    )}
                    sx={{
                      ...(!list.isRead && {
                        bgcolor: 'action.selected',
                      }),
                    }}
                  >
                    <ListItemButton
                      onClick={() => openNotification(list)}
                      sx={{ '&:hover': { backgroundColor: 'transparent' } }}
                    >
                      <ListItemAvatar>
                        <Avatar alt={list.name} src={list.image || list.name} />
                      </ListItemAvatar>
                      <ListItemText primary={list.title} secondary={list.message} />
                    </ListItemButton>
                  </ListItem>
                ) : null}
              </>
            ))}
          </>
        ))}
      </List>
    </Box>
  );
}

NotificationsPaper.defaultProps = {
  subheaderDate: [],
  notifications: [],
};
