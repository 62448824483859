/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-unresolved */
import { Grid, Link, Stack } from '@mui/material';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AccessAlarmOutlinedIcon from '@mui/icons-material/AccessAlarmOutlined';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { generalInfoState } from '../visit.model';
import { getFormatDate } from '../../../utils/Validate';
import { DetailTitles, GeneralInfoText } from '../components/visitComponents';

export default function GeneralInfomation(props: generalInfoState) {
  const {
    doctorName, date, hour, reason, role, levelLocal, localAddress, urlMap,
  } = props;
  return (
    <>
      <DetailTitles text="DETALLES DE LA CITA" style={{ fontWeight: 600, mb: 2 }} />
      <Grid container spacing={2} direction="column">
        <Grid item md={4} xs={1}>
          <GeneralInfoText
            text={getFormatDate(date, hour, 1)}
            role={role}
            IconInfo={CalendarMonthOutlinedIcon}
          />
        </Grid>
        <Grid item md={4} xs={1}>
          <GeneralInfoText
            text={getFormatDate(date, hour, 2)}
            role={role}
            IconInfo={AccessAlarmOutlinedIcon}
          />
        </Grid>
        {doctorName != null && (
        <Grid item md={4} xs={1} sx={{ display: { xs: 'block', sm: 'none' } }}>
          <GeneralInfoText text={doctorName} role={role} IconInfo={MedicalServicesOutlinedIcon} />
        </Grid>
        )}
        <Grid item md={4} xs={1}>
          <GeneralInfoText
            text={`${localAddress}, ${levelLocal}`}
            role={role}
            IconInfo={LocationCityOutlinedIcon}
          />
        </Grid>
        <Grid item md={4} xs={1}>
          <Stack direction="row" spacing={1}>
            <LocationOnIcon color={role === 'Proveedor' ? 'primary' : 'secondary'} />
            <Link
              component="button"
              variant="body2"
              sx={{ fontWeight: 600, mt: 2 }}
              onClick={() => window.open(urlMap, '_blank', 'noreferrer')}
            >
              Ver Mapa
            </Link>
          </Stack>
        </Grid>
        <Grid item md={4} xs={1}>
          <GeneralInfoText text={reason} role={role} IconInfo={FactCheckOutlinedIcon} />
        </Grid>
      </Grid>
    </>
  );
}
