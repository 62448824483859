/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-unresolved */
import * as React from 'react';
import {
  Button, Stack, Box, Typography, Divider,
} from '@mui/material';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { ProfileProps } from '../../profile.model';
import { DoctorSchedule, UserProfileModal } from '../../../../redux/store/profile';
import PatientCard from '../../../../utils/patientCard';
import { LoadingStyleProgress } from '../../../../utils/loadingsFormats';

export default function PatientProfile(props: ProfileProps) {
  const { userId, showButtons } = props;
  const history = useHistory();
  const State = useSelector((GenState: any) => GenState);
  const [loading, setLoading] = React.useState(true);
  const [userDocData, setUserDocData] = React.useState<any>(null);
  const [value, setValue] = React.useState<any>({
    name: '',
    email: '',
    image: '',
    gender: '',
    birthDate: '',
    phone: '',
  });

  const { auth: user } = State;

  React.useEffect(() => {
    const fetchData = async () => {
      const result = await UserProfileModal(userId);

      if (result != null) {
        setValue(result);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);
  }, []);

  React.useEffect(() => {
    if (user.roles[0] == 'Subordinate') {
      setUserDocData(user.doctorProfileData);
    } else setUserDocData(user);
  }, [user]);

  const clickPublicProfile = async () => {
    const doctorSchedule = await DoctorSchedule(userDocData?.userId);
    const doctorData = {
      doctorId: userDocData?.userId,
      name: userDocData?.name,
      picture: userDocData?.url,
      department: doctorSchedule?.department,
      rating: userDocData?.rating != null ? userDocData?.rating : '5.0',
    };
    const patientData = {
      userId,
      name: value.name,
      image: value.image,
      phone: value.phone,
      email: value.email,
    };
    if (doctorSchedule != null) {
      history.push('/visit/create', {
        doctorData,
        patientData,
        doctorSchedule,
      });
    }
  };

  const clickEditProfile = () => {
    history.push('/profile/edit');
  };

  return (
    <>
      {loading == true
        ? (
          <LoadingStyleProgress styleLoading={{ color: '#936AD0' }} />
        ) : (
          <>
            <Stack justifyContent="center" alignItems="center" p={2} spacing={2} direction={{ sm: 'row', xs: 'column' }}>
              <PatientCard
                name={value.name}
                email={value.email}
                picture={value.image}
              />
              <Divider flexItem orientation="vertical" sx={{ display: { xs: 'none', sm: 'flex' } }} />
              <Divider flexItem orientation="horizontal" sx={{ display: { xs: 'flex', sm: 'none' } }} />
              <Stack justifyContent="flex-start" alignItems="flex-start" spacing={1} direction="column" sx={{ width: { xs: '100%', sm: 'auto' } }}>
                <Box>
                  <Typography variant="body2" color="textSecondary">
                    Género:
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    {value.gender != null ? `${value.gender}` : 'Ninguno'}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2" color="textSecondary">
                    Fecha de nacimiento:
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    {moment(value.birthDate).format('DD/MM/YY')}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2" color="textSecondary">
                    Número de celular:
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    {value.phone}
                  </Typography>
                </Box>
              </Stack>
            </Stack>
            <Stack
              direction="row"
              justifyContent={{ sm: 'flex-end', xs: 'center' }}
              alignItems="center"
              spacing={2}
              sx={{ mt: 5 }}
            >
              {props.private == true ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={clickEditProfile}
                >
                  EDITAR PERFIL
                </Button>
              ) : showButtons == true ? (
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={clickPublicProfile}
                >
                  PROGRAMAR CITA
                </Button>
              ) : null}
            </Stack>
          </>
        )}
    </>
  );
}
