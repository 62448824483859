/* eslint-disable react/require-default-props */
import * as React from 'react';
import { useSelector } from 'react-redux';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import {
  Button, Menu, MenuItem, Stack, Box,
} from '@mui/material';
import {
  WhatsappShareButton,
  EmailShareButton,
  WhatsappIcon,
  EmailIcon,
} from 'react-share';
import { DetailTextInfo, DetailTitles } from '../pages/visit/components/visitComponents';

export default function QR(props: { qr: any, toleration?: any }) {
  const { qr, toleration } = props;
  const State = useSelector((GenState: any) => GenState);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { auth: user } = State;
  const userRole = user.roles[0];
  const [infoQR, setInfoQR] = React.useState('El edificio Celtara, controla el acceso utilizando códigos QR, '
  + 'úsalo para entrar al estacionamiento, abrir puertas, utilizar los elevadores y seleccionar el nivel.');

  React.useEffect(() => {
    if (toleration != null) {
      const hours = Math.floor(toleration / 60);
      const minutes = toleration - (hours * 60);

      // Crear una cadena que formatea las horas y minutos en texto
      let text = '';
      if (hours > 0) text += `${hours} hora${hours > 1 ? 's' : ''}`;
      if (minutes > 0) {
        if (text.length > 0) text += ' y ';
        text += `${minutes} minuto${minutes > 1 ? 's' : ''}`;
      } else text = `${toleration} minutos`;
      setInfoQR((prev) => `${prev} El código te permite acceder al estacionamiento ${text} antes de la hora de tu cita.`);
    }
  }, [toleration]);

  const handleShareClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Stack
        direction={{ sm: 'column', xs: 'column' }}
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Box sx={{ width: 'auto', height: 'auto' }}>
          <DetailTitles text="INFORMACIÓN" style={{ fontWeight: 600, marginBottom: 1, textAlign: { sm: 'center', xs: 'left' } }} />
          <DetailTextInfo
            text={infoQR}
            variant="subtitle1"
            style={{ fontSize: '13px', textAlign: 'left' }}
          />
        </Box>
        <Box sx={{ width: 'auto', height: 'auto' }}>
          <DetailTitles text="CÓDIGO QR" style={{ fontWeight: 600, marginBottom: 1, textAlign: { sm: 'center', xs: 'left' } }} />
          <img src={qr} width="80%" height="80%" alt="QR" />
        </Box>
        <Box>
          <Button
            fullWidth
            variant="text"
            startIcon={<ShareOutlinedIcon />}
            onClick={handleShareClick}
            color={userRole != 'Proveedor' ? 'primary' : 'secondary'}
          >
            COMPARTIR
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            sx={{ button: { alignItems: 'center', display: 'flex', svg: { mr: 1, width: 24, height: 24 } } }}
          >
            <MenuItem onClick={handleClose}>
              <EmailShareButton subject="QR" body="" url={qr}>
                <EmailIcon size="2rem" round />
                Email
              </EmailShareButton>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <WhatsappShareButton title="QR" separator=":: " url={qr}>
                <WhatsappIcon size="2rem" round />
                Whatsapp
              </WhatsappShareButton>
            </MenuItem>
          </Menu>
        </Box>
      </Stack>
    </>
  );
}
