import { UserSearchDto } from '@alexnoriega3/terravital_api';
import Swal from 'sweetalert2';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import {
  UserController, DepartmentController, VisitController, SubDepartmentController,
} from '../../../services/apiTerraVital';
import { VisitList } from '../../../utils/Validate';
import { finishLoading, startLoading } from '../ui';
import {
  updateVisitData, _getAllDepartments, _getAllSubDepartments, _getMostVisited, _getPatient,
  _getSearchDoctor, _getVisits, _getDetailVisit, _cancelVisit, _completeVisit,
} from './visitSlice';

export const getAllDepartments = () => async (dispatch: any) => {
  try {
    dispatch(startLoading());
    const result = await DepartmentController.apiDepartmentGet();

    if (result.status == 200) {
      dispatch(_getAllDepartments(result.data));
    } else {
      Swal.fire('Error', 'Error', 'error');
    }
    dispatch(finishLoading());
  } catch (e: any) {
    Swal.fire('Error', e.message, 'error');
    dispatch(finishLoading());
  }
};

export const getAllSubDepartments = () => async (dispatch: any) => {
  try {
    dispatch(startLoading());
    const result = await SubDepartmentController.apiSubdepartmentGet();

    if (result.status == 200) {
      dispatch(_getAllSubDepartments(result.data));
    } else {
      Swal.fire('Error', 'Error', 'error');
    }
    dispatch(finishLoading());
  } catch (e: any) {
    Swal.fire('Error', e.message, 'error');
    dispatch(finishLoading());
  }
};

export const getMostVisitedByID = (userId: string) => async (dispatch: any) => {
  try {
    dispatch(startLoading());
    const result = await UserController.apiUserMostVisitedUserIdPost(userId);

    if (result.status == 200) {
      dispatch(_getMostVisited(result.data));
    } else {
      Swal.fire('Error', 'register Error', 'error');
    }
    dispatch(finishLoading());
  } catch (e: any) {
    Swal.fire('Error', e.message, 'error');
    dispatch(finishLoading());
  }
};

export const getSearchDoctor = (credentials: UserSearchDto) => (dispatch: any) => {
  UserController.apiUserByRoleAndDepartmentPost(credentials)
    .then((result: any) => {
      if (result.status == 200) {
        dispatch(_getSearchDoctor({
          residents: result.data,
          departmentsId: credentials.departments,
        }));
      } else {
        Swal.fire('Error', 'Error', 'error');
      }
    }).catch((e) => {
      Swal.fire('Error', e.message, 'error');
    });
};

export const getPatient = (providerId: string, page: number, pageSize: number, search?: string) => async (dispatch: any) => {
  try {
    dispatch(startLoading());
    const result = await UserController.apiUserSearchUserByProviderGet(providerId, page, pageSize, search);

    if (result.status == 200) {
      dispatch(_getPatient(result.data));
    } else {
      Swal.fire('Error', 'Error', 'error');
    }

    dispatch(finishLoading());
  } catch (e: any) {
    Swal.fire('Error', e.message, 'error');
    dispatch(finishLoading());
  }
};

export const getVisits = (userId: string, page: number, pageSize: number, search?: string) => async (dispatch: any) => {
  try {
    dispatch(startLoading());
    const result = await VisitController.apiVisitHistoricGet(userId, page, pageSize, search);

    if (result.status == 200) {
      dispatch(_getVisits(result.data));
    } else {
      Swal.fire('Error', 'register Error', 'error');
    }

    dispatch(finishLoading());
  } catch (e: any) {
    Swal.fire('Error', e.message, 'error');
    dispatch(finishLoading());
  }
};

export const getDetailVisit = (visitId: string) => async (dispatch: any) => {
  try {
    dispatch(startLoading());
    const result = await VisitController.apiVisitDetailVisitIdGet(visitId);

    if (result.status == 200) {
      dispatch(_getDetailVisit(result.data));
    } else {
      Swal.fire('Error', 'register Error', 'error');
    }

    dispatch(finishLoading());
  } catch (e: any) {
    Swal.fire('Error', e.message, 'error');
    dispatch(finishLoading());
  }
};

export const editVisit = (credentials: any, visitId: string) => async (dispatch: any) => {
  try {
    dispatch(startLoading());
    const result = await VisitController.apiVisitIdPut(credentials, visitId);

    if (result.status == 200) {
      dispatch(updateVisitData(credentials));
      dispatch(getDetailVisit(visitId));
      localStorage.setItem('loadNotifications', 'false');
      Swal.fire('Guardado', 'Cita Guardada', 'success').then((okay) => {
        if (okay) {
          window.location.replace('/visit/detail');
        }
      });
    } else {
      Swal.fire('Error', 'Error', 'error');
    }

    dispatch(finishLoading());
  } catch (e: any) {
    Swal.fire('Error', e.message, 'error');
    dispatch(finishLoading());
  }
};

export const cancelVisit = (credentials: any) => async (dispatch: any) => {
  try {
    dispatch(startLoading());
    const result = await VisitController.apiVisitIdPut(credentials, credentials.visitId);

    if (result.status == 200) {
      dispatch(_cancelVisit(credentials));
      localStorage.setItem('loadNotifications', 'false');
      toastr.success('Su cita ha sido cancelada.', '¡Éxito!');
    } else {
      Swal.fire('Error', 'Error', 'error');
    }

    dispatch(finishLoading());
  } catch (e: any) {
    Swal.fire('Error', e.message, 'error');
    dispatch(finishLoading());
  }
};

export const completeVisit = (visitId: any) => async (dispatch: any) => {
  try {
    dispatch(startLoading());
    const result = await VisitController.apiVisitCompleteVisitIdPut(visitId);

    if (result.status == 200) {
      dispatch(_completeVisit());
      localStorage.setItem('loadNotifications', 'false');
      toastr.success('Su cita ha sido completada.', '¡Éxito!');
    } else {
      Swal.fire('Error', 'Error', 'error');
    }

    dispatch(finishLoading());
  } catch (e: any) {
    Swal.fire('Error', e.message, 'error');
    dispatch(finishLoading());
  }
};

export async function getMostVisited(userId: string) {
  try {
    const result = await UserController.apiUserMostVisitedUserIdPost(userId);

    if (result.status == 200) {
      return result.data;
    }
    return [];
  } catch (e: any) {
    Swal.fire('Error', e.message, 'error');
    return [];
  }
}

export async function PatientVisitList(userId: any, page: number, pageSize: number, search?: string) {
  try {
    const result = await VisitController.apiVisitHistoricGet(userId, page, pageSize, search);

    if (result.status == 200) {
      const data = {
        scheduledList: VisitList(result.data.items, 0),
        historyList: VisitList(result.data.items, 1),
      };
      return data;
    }
    return null;
  } catch (e: any) {
    Swal.fire('Error', e.message, 'error');
    return null;
  }
}

export async function ResidentVisit(residentId: string, date: string) {
  try {
    const result = await VisitController.apiVisitResidentResidentIdVistDateDateGet(residentId, date);

    if (result.status == 200) {
      return result.data;
    }
    return [];
  } catch (e: any) {
    Swal.fire('Error', e.message, 'error');
    return [];
  }
}

export async function createNewVisit(credentials: any) {
  try {
    const result = await VisitController.apiVisitPost(credentials);

    if (result.status == 200) {
      return result.data;
    }
    return null;
  } catch (e: any) {
    Swal.fire('Error', e.message, 'error');
    return null;
  }
}
