/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
/* eslint-disable import/no-unresolved */
import * as React from 'react';
import {
  Avatar, Badge, Box, Button, CardActions, CardContent, Chip,
  Divider, Fab, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper,
  Select, Stack, TextField, Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { getAllDepartments, getAllSubDepartments } from '../../../../redux/store/visit';
import {
  academicStudiesDTO, departmentsListDTO, subDepartmentsListDTO,
} from '../../profile.model';
import SchedulesModal from '../doctor/schedulesModal';
import PageTitle from '../../../../components/shared/PageTitle';
import { isValidPhone } from '../../../../utils/Validate';
import { editDoctorProfile, uploadImage } from '../../../../redux/store/auth';
import { SearchByPhone } from '../../../../redux/store/profile';

const Input = styled('input')({
  display: 'none',
});

export default function EditDoctorProfile() {
  const dispatch = useDispatch();
  const state = useSelector((GenState: any) => GenState);
  const { auth: user, visit } = state;
  const loadAllDepartments = localStorage.getItem('loadAllDepartments');
  const loadAllSubDep = localStorage.getItem('loadAllSubDep');
  const subDepId = user.subDepartments != null ? user.subDepartments.map(((value: any) => (value.key))) : null;
  const [imagenBase64, setImagenBase64] = React.useState('');
  const [blob, setBlob] = React.useState({});
  const [subDep, setSubDep] = React.useState<subDepartmentsListDTO[]>([]);
  const [day, setDay] = React.useState<any[]>(user.schedules[0] != null ? user.schedules : []);
  const [academicStudies, setAcademicStudies] = React.useState<academicStudiesDTO[]>(
    user.academicStudies[0] != null ? user.academicStudies
      : [{
        academicStudiesId: '',
        userId: user.userId,
        degree: '',
        school: '',
      }],
  );
  const [values, setValues] = React.useState({
    firstName: user.firstName,
    lastName: user.lastName,
    titleAbbreviation: user.gender == 'Femenino' ? 'Dra' : 'Dr',
    phone: user.phone != null ? user.phone : '',
    department: user.department[0] != null ? user.department[0].key : '',
    cost: user.costPerAppointment != null ? user.costPerAppointment : 0,
    localId: user.levelLocal[0] != null ? user.levelLocal[0].key : '',
  });
  const [error, setError] = React.useState({
    firstName: false,
    lastName: false,
    phone: false,
    department: false,
    subDep: false,
    schedules: false,
  });

  const isValid = values.firstName != '' && values.lastName != '' && values.phone != '' && values.localId != '' && values.department[0] != null && subDep[0] != null && day[0] != null;

  React.useEffect(() => {
    if (user.userId != null) {
      if (loadAllDepartments != 'true') {
        localStorage.setItem('loadAllDepartments', 'true');
        dispatch(getAllDepartments() as any);
      }

      if (loadAllSubDep != 'true') {
        localStorage.setItem('loadAllSubDep', 'true');
        dispatch(getAllSubDepartments() as any);
      }
      subDepId[0] != null
        ? setSubDep(visit.subDepartmentsList.filter((o1: subDepartmentsListDTO) => subDepId.some((o2: any) => o1.subdepartmentId === o2)))
        : setSubDep(visit.subDepartmentsList.filter((o1: subDepartmentsListDTO) => user.subDepartments.some((o2: any) => o1.subdepartmentId === o2)));
    }
  }, [dispatch]);

  const handleChange = (e: any) => {
    if (e.target.name == 'phone') {
      if (e.target.value === '' || isValidPhone(e.target.value)) {
        setValues({ ...values, [e.target.name]: e.target.value });
      }
    } else if (e.target.name == 'department') {
      setValues({ ...values, [e.target.name]: e.target.value });
      if (e.target.value == user.department[0].key && user.department[0] != null) {
        subDepId[0] != null
          ? setSubDep(visit.subDepartmentsList.filter((o1: subDepartmentsListDTO) => subDepId.some((o2: any) => o1.subdepartmentId === o2)))
          : setSubDep(visit.subDepartmentsList.filter((o1: subDepartmentsListDTO) => user.subDepartments.some((o2: any) => o1.subdepartmentId === o2)));
      } else { setSubDep([]); }
    } else if (e.target.name == 'cost') {
      if (parseInt(e.target.value, 10) <= 100000 && /^[0-9\b]+$/.test(e.target.value)) {
        setValues({ ...values, [e.target.name]: e.target.value });
      } else if (e.target.value == '') setValues({ ...values, [e.target.name]: '' });
    } else { setValues({ ...values, [e.target.name]: e.target.value }); }
    setError({ ...error, [e.target.name]: e.target.value.length == 0 });
  };

  const ManejarOnChange = (e: any) => {
    if (e.currentTarget.files) {
      const archivo = e.currentTarget.files[0];
      aBase64(archivo)
        .then((representacionBase64: string) => setImagenBase64(representacionBase64))
        .catch((err: any) => console.error(err));
      setBlob(archivo);
    }
  };

  const aBase64 = (file: File) => new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

  const handleClick = (newFormats: subDepartmentsListDTO) => () => {
    const newValue = [...subDep];
    newValue.push(newFormats);
    setSubDep(newValue);
    setError({ ...error, subDep: false });
  };

  const handleDelete = (chipToDelete: subDepartmentsListDTO) => () => {
    setSubDep((subDep) => subDep.filter((sub) => sub.subdepartmentId !== chipToDelete.subdepartmentId));
    setError({ ...error, subDep: subDep.length == 1 });
  };

  const handleClickStudies = (action: string, index: number) => () => {
    if (action == 'add') {
      const newValue = [...academicStudies];
      newValue.push({
        academicStudiesId: '',
        userId: user.userId,
        degree: '',
        school: '',
      });
      setAcademicStudies(newValue);
    } else {
      setAcademicStudies((academicStudies) => academicStudies.filter((_item, i) => i !== index));
    }
  };

  const handleChangeStudies = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newArr = academicStudies.map((item, i) => {
      if (index == i) {
        return { ...item, [event.target.name]: event.target.value };
      }
      return item;
    });
    setAcademicStudies(newArr);
  };

  const Submit = async () => {
    if (isValid) {
      const subDeparts = subDep.map(((value) => (value.subdepartmentId)));
      const newDepartment = visit.departmentsList.filter((item: any) => (item.departmentId == values.department)).map((value: any) => ({ key: value.departmentId, value: value.name }));
      const profileData = {
        imageFile: undefined,
        name: `${values.titleAbbreviation} ${values.firstName} ${values.lastName}`,
        firstName: values.firstName,
        lastName: values.lastName,
        url: user?.image,
        countryCode: '',
        titleAbbreviation: values.titleAbbreviation,
        gender: user.gender,
        phone: values.phone,
        country: '',
        city: user.city,
        address: user.address,
        birthDate: user.birthDate,
        costPerAppointment: values.cost != '' ? parseInt(values.cost, 10) : 0,
        localId: values.localId,
        levelLocal: user.levelLocal,
        department: newDepartment,
        subDepartments: subDeparts,
        academicStudies,
        schedules: day,
      };
      const result = await SearchByPhone(values.phone);
      if (result != null) {
        if (user.userId != result?.id) {
          Swal.fire('Alerta', 'Número de celular ya existente.', 'warning');
        } else {
          dispatch(uploadImage(user.userId, blob) as any);
          dispatch(editDoctorProfile(user.userId, profileData) as any);
        }
      } else {
        dispatch(uploadImage(user.userId, blob) as any);
        dispatch(editDoctorProfile(user.userId, profileData) as any);
      }
    } else if (values.localId == '') {
      Swal.fire('Alerta', 'No tiene asignado un local, contacte a su administrador.', 'warning');
    } else {
      Swal.fire('Campos Requeridos', 'Completar los campos requeridos.', 'warning');
      setError({
        firstName: values.firstName == '',
        lastName: values.lastName == '',
        phone: values.phone == '',
        department: values.department[0] == null,
        subDep: subDep[0] == null,
        schedules: day[0] == null,
      });
    }
  };

  return (
    <>
      <PageTitle kTitle="Editar Perfil" />
      <CardContent>
        <Stack direction={{ xs: 'column', md: 'row' }} alignItems="center" justifyContent="space-between" spacing={6}>
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={(
              <label htmlFor="icon-button-file">
                <Input
                  accept=".jpg,.jpeg,.png,.webp,.bmp,.tiff"
                  id="icon-button-file"
                  type="file"
                  onChange={(e) => ManejarOnChange(e)}
                />
                <Fab color="secondary" size="small" aria-label="upload picture" component="span" sx={{ boxShadow: 'none' }}>
                  <AddAPhotoOutlinedIcon />
                </Fab>
              </label>
              )}
          >
            <Avatar
              src={imagenBase64 || user?.image}
              alt={imagenBase64 || user?.image}
              sx={{
                mt: 4,
                height: 155,
                width: 155,
                border: (t) => `1px solid ${t.palette.divider}`,
              }}
            />
          </Badge>
          <Box sx={{ width: '100%' }}>
            <Typography variant="body1" gutterBottom color="textSecondary">
              Información Básica
            </Typography>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Nombre(s)"
                  name="firstName"
                  onChange={handleChange}
                  required
                  value={values.firstName}
                  error={error.firstName}
                  variant="filled"
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Apellido(s)"
                  name="lastName"
                  onChange={handleChange}
                  required
                  value={values.lastName}
                  error={error.lastName}
                  variant="filled"
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Celular"
                  name="phone"
                  onChange={handleChange}
                  required
                  value={values.phone}
                  error={error.phone}
                  variant="filled"
                  inputProps={{ maxLength: 10 }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl variant="filled" sx={{ width: '100%' }}>
                  <InputLabel>Especialidad</InputLabel>
                  <Select
                    labelId="department"
                    name="department"
                    value={values.department}
                    error={error.department}
                    onChange={handleChange}
                    required
                  >
                    {visit.departmentsList && visit.departmentsList.length > 0
                      ? visit.departmentsList.map((option: departmentsListDTO) => (
                        <MenuItem key={option.departmentId} value={option.departmentId}>
                          {option.name}
                        </MenuItem>
                      )) : null}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Stack>
        <Divider flexItem sx={{ my: 4 }} />
        <Typography variant="body1" gutterBottom color="textSecondary">
          Subespecialidad
        </Typography>
        <Grid container spacing={3}>
          <Grid item md={12} xs={12}>
            <FormControl fullWidth>
              <Select
                multiple
                variant="filled"
                value={subDep}
                error={error.subDep}
                inputProps={{ readOnly: true }}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value.subdepartmentId}
                        label={value.name}
                        variant="outlined"
                        onDelete={handleDelete(value)}
                      />
                    ))}
                  </Box>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item md={12} xs={12}>
            {values.department != ''
              ? (
                <Paper
                  elevation={0}
                  sx={{
                    listStyle: 'none',
                    pl: 0,
                    m: 0,
                    whiteSpace: 'nowrap',
                    overflow: 'auto',
                    paddingBottom: '15px',
                  }}
                  component="ul"
                >
                  {visit.subDepartmentsList && visit.subDepartmentsList.length > 0
                    ? visit.subDepartmentsList.map((value: subDepartmentsListDTO) => (
                      values.department == value.departmentId ? (
                        subDep.includes(value) ? null : (
                          <Chip
                            key={value.subdepartmentId}
                            label={value.name}
                            variant="outlined"
                            color="default"
                            onClick={handleClick(value)}
                            sx={{ mr: 1 }}
                          />
                        )
                      ) : null
                    )) : null}
                </Paper>
              )
              : null}
          </Grid>
          {academicStudies.map((value: academicStudiesDTO, index: number) => (
            <Grid key={index} item md={12} xs={12}>
              <Stack direction="row" spacing={2}>
                <TextField
                  fullWidth
                  placeholder="Estudios"
                  name="degree"
                  onChange={handleChangeStudies(index)}
                  required
                  value={value.degree}
                  variant="filled"
                  inputProps={{ maxLength: 2500 }}
                />
                <TextField
                  fullWidth
                  placeholder="Escuela"
                  name="school"
                  onChange={handleChangeStudies(index)}
                  required
                  value={value.school}
                  variant="filled"
                  inputProps={{ maxLength: 100 }}
                />
                {index > 0
                  ? (
                    <IconButton color="secondary" onClick={handleClickStudies('delete', index)}>
                      <DeleteOutlineIcon />
                    </IconButton>
                  ) : (
                    <IconButton color="secondary" onClick={handleClickStudies('add', index)}>
                      <AddIcon />
                    </IconButton>
                  )}
              </Stack>
            </Grid>
          ))}
        </Grid>
        <Divider flexItem sx={{ my: 4 }} />
        <Typography variant="body1" gutterBottom color="textSecondary">
          Sobre las Citas
        </Typography>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <SchedulesModal
              schedules={user.schedules}
              error={error.schedules}
              onSubmit={(schedules) => {
                const newArr = schedules.map((value) => (
                  value.secondHourOpen != '' ? { ...value }
                    : {
                      scheduleId: value.scheduleId,
                      day: value.day,
                      hourOpen: value.hourOpen,
                      hourClose: value.hourClose,
                      orderDay: value.orderDay,
                      active: value.active,
                    }
                ));
                setDay(newArr);
                setError({ ...error, schedules: newArr[0] == null });
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Costo por cita"
              name="cost"
              onChange={handleChange}
              required
              value={values.cost}
              variant="filled"
              InputProps={{ inputProps: { min: 0, max: 100000 } }}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions>
        <Box sx={{
          display: 'flex', width: '100%', justifyContent: 'flex-end', p: 2,
        }}
        >
          <Button
            color="secondary"
            variant="contained"
            onClick={Submit}
            sx={{ minWidth: 120 }}
          >
            GUARDAR
          </Button>
        </Box>
      </CardActions>
    </>
  );
}
