/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-undef */
/* eslint-disable react/require-default-props */
import { Box, Stack, Typography } from '@mui/material';

export interface DetailTitlesModels {
    text: string;
    style: any;
}

export function DetailTitles(props: DetailTitlesModels) {
  return (
    <Typography variant="subtitle1" align="left" color="GrayText" sx={props.style}>
      {props.text}
    </Typography>
  );
}

export interface DetailTextInfoModels {
    text: string;
    variant: any;
    style: any;
}

export function DetailTextInfo(props: DetailTextInfoModels) {
  return (
    <Typography variant={props.variant} sx={props.style}>
      {props.text}
    </Typography>
  );
}

export interface GeneralInfoTextModels {
    text: any;
    role: string;
    IconInfo: any;
}

export function GeneralInfoText(props: GeneralInfoTextModels) {
  const { text, role, IconInfo } = props;
  return (
    <>
      <Stack direction="row" spacing={1}>
        <IconInfo color={role === 'Proveedor' ? 'primary' : 'secondary'} />
        <Typography variant="subtitle1" gutterBottom>
          {text}
        </Typography>
      </Stack>
    </>
  );
}

export function DetailComments(props: { text: string }) {
  return (
    <Box sx={{
      width: '100%', minHeight: 100, backgroundColor: '#f8f8f8', borderRadius: '8px', p: 2,
    }}
    >
      <Typography
        variant="subtitle1"
        gutterBottom
        style={{ wordWrap: 'break-word', overflowWrap: 'anywhere' }}
      >
        {props.text}
      </Typography>
    </Box>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}
