/* eslint-disable import/no-unresolved */
import {
  Typography, Box, Card, CardMedia, Stack, IconButton,
} from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Navigation, Pagination,
} from 'swiper';
import { useHistory } from 'react-router-dom';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { getAllDepartments } from '../../../redux/store/visit';
import { departmentsListDTO } from '../../visit/visit.model';
import img4 from '../../../images/4.png';
import 'swiper/css';
import 'swiper/css/navigation';
import { resetLoadings } from '../../../redux/store/profile';

export default function HomeDepartments() {
  const history = useHistory();
  const dispatch = useDispatch();
  const state = useSelector((GenState: any) => GenState);
  const loadAllDepartments = localStorage.getItem('loadAllDepartments');

  const { auth: user, ui: { loading }, visit } = state;

  useEffect(() => {
    if (!loading && loadAllDepartments != 'true' && user.userId != null) {
      localStorage.setItem('loadAllDepartments', 'true');
      dispatch(getAllDepartments() as any);
    }
  }, [visit.departmentsList]);

  const handleCardClick = (department: any) => () => {
    dispatch(resetLoadings() as any);
    history.push('/search', {
      userRole: user.roles[0],
      department,
    });
  };

  return (
    <Box sx={{ pt: 6 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
        <Typography variant="h6" component="h6" mb={1}>
          Especialidades
        </Typography>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <IconButton
            size="medium"
            aria-label="left"
            className="prev"
            sx={{ '&.Mui-disabled': { opacity: 0.2 } }}
          >
            <ArrowBackIosNewRoundedIcon color="primary" />
          </IconButton>
          <IconButton
            size="medium"
            aria-label="right"
            className="next"
            sx={{ '&.Mui-disabled': { opacity: 0.2 } }}
          >
            <ArrowForwardIosRoundedIcon color="primary" />
          </IconButton>
        </Stack>
      </Stack>
      <Swiper
        cssMode
        pagination
        mousewheel
        keyboard
        freeMode
        slidesPerGroup={1}
        breakpoints={{
          900: {
            slidesPerView: 4,
            spaceBetween: 16,
          },
          360: {
            slidesPerView: 3,
            spaceBetween: 12,
          },
          0: {
            slidesPerView: 1,
            spaceBetween: 8,
          },
        }}
        navigation={{
          prevEl: '.prev',
          nextEl: '.next',
          disabledClass: 'Mui-disabled',
        }}
        modules={[Navigation, Pagination]}
        className="mySwiper"
      >
        {visit.departmentsList && visit.departmentsList.length > 0
          ? visit.departmentsList.map((value: departmentsListDTO) => (
            <SwiperSlide key={value.departmentId}>
              <Card variant="outlined" sx={{ borderRadius: '8px', cursor: 'pointer' }}>
                <Stack
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ height: '130px' }}
                  onClick={handleCardClick(value.departmentId)}
                >
                  <CardMedia
                    component="img"
                    style={{ width: 'auto', height: '40px', margin: '0 auto' }}
                    image={value.image != null ? value.image : img4}
                    alt={value.name}
                  />
                  <Typography variant="body1" component="div" sx={{ textAlign: 'center' }}>
                    {value.name}
                  </Typography>
                </Stack>
              </Card>
            </SwiperSlide>
          )) : null}
      </Swiper>
    </Box>
  );
}
