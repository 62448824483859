/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-plusplus */
/* eslint-disable import/no-unresolved */
import * as React from 'react';
import {
  Box, Button, Card,
  CardActionArea, Dialog, DialogActions,
  DialogContent, DialogTitle,
  IconButton,
  Paper,
  Stack, styled, Tab, Tabs, TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import moment from 'moment';
import { LocalizationProvider, CalendarPicker } from '@mui/x-date-pickers';

import { es } from 'date-fns/locale';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useSelector } from 'react-redux';
import toastr from 'toastr';
import { dateModalProps } from '../visit.model';
import { removeAccents } from '../../../utils/Validate';
import { TabPanel } from '../components/visitComponents';

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function formatDate(date: string, hour: string) {
  const formattedDate = moment(date).format('DD/MM/yyyy');
  const formattedHour = moment(hour, ['H:mm']).format('H:mm');
  return `${formattedDate} - ${formattedHour} hrs.`;
}

function isDisabled(date: any, hour: any, busyDates: any) {
  let disabled;
  const realMinutes = moment().format('mm');
  const realHour = moment().format('H');
  const minutes = moment(hour, ['H:mm']).format('mm');
  const today = moment().format('YYYY-MM-DD');
  const busyHours = busyDates.filter((item: any) => (item.date == moment(date).format('YYYY-MM-DD'))).map((value: any) => (value.startHour));

  if (moment(date, ['YYYY-MM-DD']).format('YYYY-MM-DD') == today) {
    const isReserved = busyHours[0] != null ? busyHours.includes(hour) : false;
    if (isReserved) {
      disabled = true;
    } else if (parseInt(hour) == parseInt(realHour)) {
      if (minutes == '30' && parseInt(realMinutes) >= 20) {
        disabled = true;
      } else if (minutes == '30' && parseInt(realMinutes) < 20) {
        disabled = false;
      } else if (minutes == '00' && parseInt(realMinutes) >= 0) {
        disabled = true;
      }
    } else if (parseInt(hour) < parseInt(realHour)) {
      disabled = true;
    } else if (parseInt(hour) > parseInt(realHour)) {
      const newHour = parseInt(hour) - parseInt(realHour);

      if (newHour == 1 && minutes == '00' && parseInt(realMinutes) >= 30) {
        disabled = true;
      } else { disabled = false; }
    }
  } else {
    const isReserved = busyHours[0] != null ? busyHours.includes(hour) : false;
    if (isReserved) {
      disabled = true;
    } else { disabled = false; }
  }

  return disabled;
}

function getHours(date: any, schedules: any) {
  moment.locale('es');
  const startHour = [];
  const secondHour = [];
  const day = moment(date, ['YYYY-MM-DD']).format('dddd');
  const activeDays = schedules.filter((item: any) => (removeAccents(item.day.toLowerCase()) == removeAccents(day))).map((value: any) => (value));

  const hourOpen = activeDays[0] != null ? parseFloat(activeDays[0].hourOpen) : 0;
  const hourClose = activeDays[0] != null ? parseFloat(activeDays[0].hourClose) : 0;

  if (activeDays[0] != null) {
    for (let hours = hourOpen; hours < hourClose; hours++) {
      startHour.push(moment({ hours }).format('H:mm'));
      startHour.push(
        moment({
          hours,
          minute: 30,
        }).format('HH:mm'),
      );
    }
    if (moment(activeDays[0].hourClose, ['H:mm']).format('mm') == '30') {
      startHour.push(moment(activeDays[0].hourClose, ['h:mm']).subtract(30, 'minutes').format('H:mm'));
    }

    if (activeDays[0].secondHourOpen != null) {
      const secondHourOpen = parseFloat(activeDays[0].secondHourOpen);
      const secondHourClose = parseFloat(activeDays[0].secondHourClose);
      for (let hours = secondHourOpen; hours < secondHourClose; hours++) {
        secondHour.push(moment({ hours }).format('H:mm'));
        secondHour.push(
          moment({
            hours,
            minute: 30,
          }).format('H:mm'),
        );
      }
      if (moment(activeDays[0].secondHourClose, ['H:mm']).format('mm') == '30') {
        startHour.push(moment(activeDays[0].secondHourClose, ['h:mm']).subtract(30, 'minutes').format('H:mm'));
      }
    }
  }

  const hours = [...startHour, ...secondHour];
  return hours;
}

export default function SchedulesModal(props: dateModalProps) {
  const Theme = useTheme();
  const isMobile = useMediaQuery(Theme.breakpoints.down('sm'));
  const { doctorSchedules: { schedules, busyDates }, onSubmit } = props;
  moment.locale('es');
  const State = useSelector((GenState: any) => GenState);
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [dateSelected, setDateSelected] = React.useState('');
  const [date, setDate] = React.useState<any>(new Date());
  const [hour, setHour] = React.useState('');
  const [hours, setHours] = React.useState<any>(['']);

  const { auth: user } = State;
  const userRole = user.roles[0];
  const Styles = {
    color: userRole == 'User' ? '#03A9DB' : '#936AD0',
  };

  const disableWeekends = (calendarDate: any) => {
    moment.locale('es');
    const day = moment(calendarDate, ['DD/MM/YY']).format('dddd');
    const activeDays = schedules.map((val: any) => (removeAccents(val.day.toLowerCase())));
    const disabledDay = activeDays.includes(removeAccents(day));
    return !disabledDay;
  };

  const handleFormat = (
    _event: React.MouseEvent<HTMLElement>,
    newHours: string,
  ) => {
    setHour(newHours);
  };

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    if (hours[0] == '') {
      const Hours = getHours(moment(date, ['YYYY-MM-DD']).format('YYYY-MM-DD'), schedules);
      setHours(Hours);
    }
    setOpen(true);
  };

  const handleClose = (_event: React.SyntheticEvent<unknown>, reason?: string) => {
    if (reason !== 'backdropClick') {
      setValue(0);
      setDate(new Date());
      setHour('');
      onSubmit('', '');
      setOpen(false);
    }
  };

  const handleClick = () => {
    if (value == 0) {
      setValue(1);
    } else if (value == 1) {
      if (hour != null && hour != '') {
        const newDate = moment(date).format('YYYY-MM-DD');
        setDateSelected(formatDate(newDate, hour));
        onSubmit(newDate, hour);
        setValue(0);
        setOpen(false);
      } else toastr.warning('Debes seleccionar hora', 'Warning');
    }
  };

  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
      margin: theme.spacing(0.3),
      border: '0px solid',
      '&:first-of-type': {
        borderRadius: 50,
        backgroundColor: '#FFFFFF',
      },
      '&.Mui-selected, &.Mui-selected:hover': {
        color: '#FFFFFF',
        backgroundColor: Styles.color,
      },
      '&.MuiToggleButton-root.Mui-disabled': {
        border: '0px solid',
      },
    },
  }));

  return (
    <>
      <Card elevation={0}>
        <CardActionArea onClick={handleClickOpen}>
          <TextField
            fullWidth
            label="Selecciona fecha y hora"
            name="state"
            error={props.error}
            value={props.date != null && props.hour != '' && hour == ''
              ? formatDate(props.date, props.hour) : dateSelected}
            variant="filled"
            required
            InputProps={{ readOnly: true }}
          />
        </CardActionArea>
      </Card>
      <Dialog
        disableEscapeKeyDown
        open={open}
        onClose={handleClose}
        fullScreen={!!isMobile}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <DialogTitle>Selecciona fecha y hora</DialogTitle>
          <IconButton color="primary" onClick={handleClose}>
            <CloseIcon sx={{ color: Styles.color }} />
          </IconButton>
        </Stack>
        <DialogContent>
          <Box sx={{ flexWrap: 'wrap', textTransform: 'capitalize' }}>
            <Typography variant="subtitle1" color="GrayText">
              {moment(date).format('YYYY')}
            </Typography>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
              <Typography variant="h4" component="h2">
                {moment(date).format('MMM DD')}
              </Typography>
              <Typography variant="h4" component="h2">
                {moment().format('H:mm')}
              </Typography>
            </Stack>
            <Box sx={{ width: '100%' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                TabIndicatorProps={{ style: { background: Styles.color } }}
              >
                <Tab
                  sx={{ '&.Mui-selected': { color: Styles.color }, '&.MuiTabs-indicator': { backgroundColor: Styles.color } }}
                  icon={<CalendarTodayIcon />}
                  aria-label="calendar"
                  {...a11yProps(0)}
                />
                <Tab
                  sx={{ '&.Mui-selected': { color: Styles.color } }}
                  icon={<AccessTimeOutlinedIcon />}
                  aria-label="hours"
                  {...a11yProps(1)}
                />
              </Tabs>
              <TabPanel value={value} index={0} dir={Theme.direction}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
                  <CalendarPicker
                    views={['day']}
                    disablePast
                    date={date}
                    onChange={(newDate) => {
                      setDate(newDate);
                      const Hours = getHours(moment(newDate, ['YYYY-MM-DD']).format('YYYY-MM-DD'), schedules);
                      setHours(Hours);
                      setHour('');
                    }}
                    shouldDisableDate={disableWeekends}
                  />
                </LocalizationProvider>
              </TabPanel>
              <TabPanel value={value} index={1} dir={Theme.direction}>
                <Paper
                  elevation={0}
                  sx={{
                    px: 0, m: 'auto', width: '100%', maxWidth: 350,
                  }}
                  component="ul"
                >
                  {
                    hours.map((time: any) => (
                      <StyledToggleButtonGroup
                        key={time}
                        color="primary"
                        size="small"
                        value={hour}
                        exclusive
                        onChange={handleFormat}
                        aria-label="text formatting"
                        sx={{ margin: 0.5 }}
                      >
                        <ToggleButton
                          sx={{ width: '85px' }}
                          value={time}
                          aria-label="bold"
                          disabled={isDisabled(date, time, busyDates)}
                        >
                          <Typography variant="button">
                            {time}
                          </Typography>
                        </ToggleButton>
                      </StyledToggleButtonGroup>
                    ))
                  }
                </Paper>
              </TabPanel>
            </Box>

          </Box>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <Button onClick={handleClick} variant="contained" color={userRole == 'User' ? 'primary' : 'secondary'}>Aplicar</Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
}
