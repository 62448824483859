/* eslint-disable import/prefer-default-export */
import {
  Box, CircularProgress, Stack, Typography,
} from '@mui/material';

export function LoadingStackProgress(props: { color: any }) {
  return (
    <Stack sx={{ color: 'grey.500', minHeight: 600 }} justifyContent="center" alignItems="center" spacing={2} direction="row">
      <CircularProgress color={props.color} />
    </Stack>
  );
}

export function LoadingStyleProgress(props: { styleLoading: any }) {
  return (
    <Stack sx={{ color: 'grey.500', minHeight: 600 }} justifyContent="center" alignItems="center" spacing={2} direction="row">
      <CircularProgress sx={props.styleLoading} />
    </Stack>
  );
}

export interface LoadingBoxProgressModels {
    styleBox: any;
    colorLoading: any;
}

export function LoadingBoxProgress(props: LoadingBoxProgressModels) {
  const { styleBox, colorLoading } = props;
  return (
    <Box sx={styleBox}>
      <CircularProgress color={colorLoading} />
    </Box>
  );
}

export function LoadingNewVisit(props: { color: any }) {
  return (
    <Stack sx={{ color: 'grey.500', minHeight: 600 }} justifyContent="center" alignItems="center" spacing={2} direction="column">
      <Typography variant="subtitle1" color="GrayText" sx={{ wordWrap: 'break-word', overflowWrap: 'anywhere', mb: { xs: 2 } }}>
        Espere un momento...
      </Typography>
      <CircularProgress color={props.color} />
    </Stack>
  );
}
