import * as React from 'react';
import {
  Avatar, Box, List, ListItem, ListItemAvatar, ListItemButton,
  ListItemIcon, ListItemText, Radio, Stack, Card, Button, TablePagination,
} from '@mui/material';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { PatientListProps } from '../../visit.model.d';
import { DoctorSchedule } from '../../../../redux/store/profile';
import CustomModal from '../../../../components/shared/Modal';
import PatientProfile from '../../../profile/src/profiles/patientProfile';

export default function PatientList(props: PatientListProps) {
  const {
    ListData, totalCount, checkedList, onSubmit, Pagination,
  } = props;

  const state = useSelector((GenState: any) => GenState);
  const { auth: user } = state;
  const doctorId = user.roles[0] === 'Subordinate' ? user.doctorProfileData.userId : user.userId;

  // Utilizamos el checkedList directamente
  const checked = checkedList;
  const [openModal, setOpenModal] = React.useState(false);
  const [idProfile, setIdProfile] = React.useState<any>();
  const [page, setPage] = React.useState(0);

  const handleChangePage = (_event: any, newPage: number) => {
    Pagination(newPage + 1);
    setPage(newPage);
  };

  const handleToggle = (patientId: string) => async () => {
    const result = await DoctorSchedule(doctorId);

    if (patientId === checked) {
      onSubmit('', []);
    } else {
      onSubmit(patientId, result ?? []);
    }
  };

  const handleClickOpen = (userId: string) => () => {
    setIdProfile(userId);
    setOpenModal(true);
  };

  return (
    <>
      <Box sx={{ pb: 3 }}>
        <List>
          <Stack spacing={2}>
            {ListData.map((list) => (
              <Card key={list.id} variant="outlined" sx={{ borderRadius: '8px' }}>
                <ListItem
                  secondaryAction={
                    (
                      <Button variant="text" color="secondary" onClick={handleClickOpen(list.id)}>
                        Ver perfil
                      </Button>
                    )
                  }
                  disablePadding
                >
                  <ListItemButton onClick={handleToggle(list.id)} dense>
                    <ListItemIcon sx={{ minWidth: 32 }}>
                      <Radio
                        edge="start"
                        checked={checked === list.id}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': list.id }}
                        sx={{ '&.Mui-checked': { color: '#936AD0' } }}
                      />
                    </ListItemIcon>
                    <ListItemAvatar sx={{ minWidth: 42 }}>
                      <Avatar alt={list.firstName} src={list.image} sx={{ width: 32, height: 32 }} />
                    </ListItemAvatar>
                    <ListItemText
                      id={list.id}
                      primary={`${list.firstName} ${list.lastName}`}
                      secondary={
                        list.visitDate
                          ? (
                            <Box sx={{ display: 'flex' }}>
                              <Box component="span" sx={{ display: { xs: 'none', sm: 'flex' } }}>
                                {'Última visita: '}
                              </Box>
                              <Box component="span">{moment(list.visitDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}</Box>
                            </Box>
                          )
                          : 'Programar cita'
                      }
                    />
                  </ListItemButton>
                </ListItem>
              </Card>
            ))}
          </Stack>
        </List>
        {totalCount > 5 && (
          <TablePagination
            component="div"
            count={totalCount}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={5}
            rowsPerPageOptions={[5]}
            labelRowsPerPage=""
          />
        )}
        <CustomModal
          handleModalToggle={() => setOpenModal(false)}
          open={openModal}
        >
          <PatientProfile userId={idProfile} private={false} showButtons={false} />
        </CustomModal>
      </Box>
    </>
  );
}
