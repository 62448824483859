/* eslint-disable no-unused-vars */
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput,
} from '@mui/material';

export interface InputPassModels {
    inputId: string;
    labelText: string;
    disabledPassword: any;
    inputError: any;
    inputValue: any;
    showPassword: boolean;
    errorText: string;
    handleInputChange(event: any): void;
    handleInputClick(): void;
    handleInputMouseDown(event: any): void;
}

export default function InputPass(props: InputPassModels) {
  const {
    inputId, labelText, disabledPassword, inputError, inputValue, showPassword, errorText,
    handleInputChange, handleInputClick, handleInputMouseDown,
  } = props;
  return (
    <FormControl fullWidth sx={{ mb: 1 }} variant="outlined">
      <InputLabel error={inputError}>{labelText}</InputLabel>
      <OutlinedInput
        fullWidth
        disabled={disabledPassword}
        label={labelText}
        margin="dense"
        name={inputId}
        id={inputId}
        onChange={(e: any) => handleInputChange(e)}
        type={showPassword ? 'text' : 'password'}
        error={inputError}
        value={inputValue}
        size="small"
        inputProps={{ maxLength: 24 }}
        endAdornment={(
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => { handleInputClick(); }}
              onMouseDown={(e: any) => handleInputMouseDown(e)}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
            )}
      />
      {errorText != '' && (
      <FormHelperText>{errorText}</FormHelperText>
      )}
    </FormControl>
  );
}
