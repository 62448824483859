import * as React from 'react';
import { getToken } from 'firebase/messaging';
import {
  Box,
  Button, Dialog, DialogActions, DialogTitle, CardContent, Divider, FormControlLabel, Grid, Stack, Switch, Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { browserName } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import PageTitle from '../../components/shared/PageTitle';
import { changePassword, ActivePushNotif, DisableAccount } from '../../redux/store/settings';
import { messaging } from '../../firebase';
import { isValidPassword } from '../../utils/Validate';
import InputPass from '../../utils/Inputs';

export default function Settings() {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPasswordNew, setShowPasswordNew] = React.useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => setOpen(true);

  const handleClose = (_event: React.SyntheticEvent<unknown>, reason?: string) => {
    if (reason !== 'backdropClick') setOpen(false);
  };

  const handleCancelProcess = () => {
    const data = { userId };
    dispatch(DisableAccount(data) as any);
  };

  const history = useHistory();
  const pushStatus = localStorage.getItem('pushStatus');
  const [disabledPassword, setDisabledPassword] = React.useState(true);
  const [values, setValues] = React.useState({
    password: '',
    newPassword: '',
    confirm: '',
  });
  const [errors, setErrors] = React.useState({
    password: false,
    newPassword: false,
    confirm: false,
  });
  const [errorText, setErrorText] = React.useState<any>({
    password: '',
    newPassword: '',
    confirm: '',
  });
  const [activePush, setActivePush] = React.useState(pushStatus == 'true');
  const isValid = values.password != '' && values.newPassword != '' && values.confirm != '';

  const State = useSelector((GenState: any) => GenState);

  const {
    auth: {
      firstName, roles, email, userId,
    },
  } = State;

  const handleChange = (event: any) => {
    setValues({ ...values, [event.target.name]: event.target.value });
    setErrors({ ...errors, [event.target.name]: false });
    if (event.target.name != 'password') { setErrorText({ ...errorText, [event.target.name]: '' }); }
  };

  const handleClickShowPassword = (type: any) => {
    switch (type) {
      case 'newPassword':
        setShowPasswordNew((prev) => !prev);
        break;
      case 'confirm':
        setShowPasswordConfirm((prev) => !prev);
        break;
      default:
        setShowPassword((prev) => !prev);
        break;
    }
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const ActivePush = async (event: any) => {
    let token: any = '';
    setActivePush(event.target.checked);
    localStorage.setItem('pushStatus', `${event.target.checked}`);
    if (event.target.checked == true) {
      token = await getToken(messaging, {
        vapidKey: 'BLOH8g8g4PYj57LMAnltygRA-d1Pi6LGGNLIfJrxIy92LOD_KS6EvwtiNiuPsMU_f3r9VkrRC72m-2G5CuyYLrs',
      }).catch((error) => console.log(error));
    }
    const data = {
      deviceId: '',
      tokenDevice: event.target.checked ? token : '',
      deviceName: `${firstName.toLowerCase()}Device`,
      subcribed: event.target.checked ? 'True' : 'False',
      active: event.target.checked,
      platForm: 'Web',
      identifier: `${browserName}_${firstName}`,
    };

    dispatch(ActivePushNotif(data) as any);
  };

  const handleCancel = () => {
    setDisabledPassword(true);
    setValues({
      password: '',
      newPassword: '',
      confirm: '',
    });
    setErrors({
      password: false,
      newPassword: false,
      confirm: false,
    });
    setErrorText({
      password: '',
      newPassword: '',
      confirm: '',
    });
  };

  const handleSave = () => {
    if (isValid) {
      const isNewPass = isValidPassword(values.newPassword);
      const isConPass = isValidPassword(values.confirm);
      if (isNewPass.isValid && isConPass.isValid) {
        const data = {
          email,
          currentPassword: values.password,
          newPassword: values.newPassword,
          confirmPassword: values.confirm,
        };
        if (values.newPassword == values.confirm) {
          dispatch(changePassword(data) as any);
        } else {
          setErrors({ ...errors, newPassword: true, confirm: true });
          setErrorText({ ...errorText, confirm: 'Contraseñas no coinciden.' });
        }
      } else {
        setErrorText({
          ...errors,
          newPassword: !isNewPass.isValid ? isNewPass.messageError : '',
          confirm: !isConPass.isValid ? isConPass.messageError : '',
        });
        setErrors({
          ...errors,
          newPassword: true,
          confirm: true,
        });
      }
    } else {
      setErrors({
        password: values.password == '',
        newPassword: values.newPassword == '',
        confirm: values.confirm == '',
      });
      Swal.fire('Campos Requeridos', 'Completar los campos requeridos', 'warning');
    }
  };

  return (
    <>
      <PageTitle kTitle="Ajustes" />
      <CardContent>
        <Grid container spacing={6} wrap="wrap">
          <Grid item sm={6} xs={12}>
            <Typography color="textPrimary" gutterBottom variant="h6">
              Notificaciones
            </Typography>
            <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={1}>
              <FormControlLabel
                control={(
                  <Switch
                    color={roles[0] == 'User' ? 'primary' : 'secondary'}
                    checked={activePush}
                    onChange={ActivePush}
                  />
                )}
                label=""
              />
              <Typography color="textPrimary" gutterBottom variant="body1">
                Notificaciones Push
              </Typography>
            </Stack>
            {/* <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={1}>
              <FormControlLabel
                control={(
                  <Switch
                    color={roles[0] == 'User' ? 'primary' : 'secondary'}
                    defaultChecked
                  />
                )}
                label=""
              />
              <Typography color="textPrimary" gutterBottom variant="body1">
                Mensajes SMS
              </Typography>
            </Stack> */}
          </Grid>
          <Grid item sm={6} xs={12}>
            <>
              <Typography color="textPrimary" gutterBottom variant="h6">
                Contraseña
              </Typography>
              {disabledPassword ? (
                <Button
                  color={roles[0] == 'User' ? 'primary' : 'secondary'}
                  variant="contained"
                  onClick={() => setDisabledPassword(!disabledPassword)}
                  sx={{ minWidth: 120 }}
                >
                  CAMBIAR CONTRASEÑA
                </Button>
              ) : (
                <>
                  <InputPass
                    inputId="password"
                    labelText="Contraseña actual"
                    disabledPassword={disabledPassword}
                    inputError={errors.password}
                    inputValue={values.password}
                    showPassword={showPassword}
                    errorText=""
                    handleInputChange={handleChange}
                    handleInputClick={() => handleClickShowPassword('password')}
                    handleInputMouseDown={handleMouseDownPassword}
                  />
                  <Divider sx={{ my: 2 }}>
                    <Typography color="textSecondary" gutterBottom variant="body2">
                      Ingresa nueva contraseña
                    </Typography>
                  </Divider>
                  <Grid item xs={12}>
                    <InputPass
                      inputId="newPassword"
                      labelText="Nueva contraseña"
                      disabledPassword={disabledPassword}
                      inputError={errors.newPassword}
                      inputValue={values.newPassword}
                      showPassword={showPasswordNew}
                      errorText={errorText.newPassword}
                      handleInputChange={handleChange}
                      handleInputClick={() => handleClickShowPassword('newPassword')}
                      handleInputMouseDown={handleMouseDownPassword}
                    />
                  </Grid>
                  <Box sx={{ mt: 2 }} />
                  <Grid item xs={12}>
                    <InputPass
                      inputId="confirm"
                      labelText="Confirmar nueva contraseña"
                      disabledPassword={disabledPassword}
                      inputError={errors.confirm}
                      inputValue={values.confirm}
                      showPassword={showPasswordConfirm}
                      errorText={errorText.confirm}
                      handleInputChange={handleChange}
                      handleInputClick={() => handleClickShowPassword('confirm')}
                      handleInputMouseDown={handleMouseDownPassword}
                    />
                  </Grid>

                  <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1} sx={{ width: '100%', mt: 2 }}>
                    <Button
                      color={roles[0] == 'User' ? 'primary' : 'secondary'}
                      variant="outlined"
                      onClick={handleCancel}
                    >
                      CANCELAR
                    </Button>
                    <Button
                      color={roles[0] == 'User' ? 'primary' : 'secondary'}
                      variant="contained"
                      onClick={handleSave}
                      sx={{ minWidth: 120 }}
                    >
                      GUARDAR
                    </Button>
                  </Stack>
                </>
              )}

            </>
          </Grid>
          {roles[0] == 'Subordinate' && (
          <Grid item sm={6} xs={12}>
            <Typography color="textPrimary" gutterBottom variant="h6">
              Cambiar Doctor
            </Typography>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => history.push('/selectDoctor')}
              sx={{ minWidth: 120 }}
            >
              SELECCIONAR OTRO DOCTOR
            </Button>
          </Grid>
          )}
          <Grid item sm={6} xs={12}>
            <Typography color="textPrimary" gutterBottom variant="h6">
              Borrar Mi Cuenta
            </Typography>
            <Button
              color="secondary"
              variant="contained"
              onClick={handleClickOpen}
              sx={{ minWidth: 120 }}
            >
              Borrar
            </Button>
            <Dialog
              open={open}
              onClose={() => setOpen(false)}
              maxWidth="xs"
            >
              <DialogTitle sx={{ px: 3, pt: 5 }}>
                <Typography variant="h6" component="span" sx={{ display: 'block', mb: 2 }}>
                  Estás a punto de eliminar tu cuenta de
                  {' '}
                  <strong>Celtara Medical Center</strong>
                  .
                </Typography>
                <Typography variant="body1" component="span" sx={{ display: 'block', mb: 2 }}>
                  Toda tu información sensible y personal será eliminada de la plataforma.
                </Typography>
                <Typography variant="h6" component="span" color="error" sx={{ fontWeight: 'bold' }}>
                  ¿Estás seguro que deseas eliminar tu cuenta?
                </Typography>
              </DialogTitle>

              <DialogActions sx={{ p: 3 }}>
                <Button
                  variant="outlined"
                  onClick={handleClose}
                  color="secondary"
                >
                  NO
                </Button>
                <Button
                  variant="contained"
                  onClick={handleCancelProcess}
                  color="secondary"
                  autoFocus
                >
                  SI
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
}
