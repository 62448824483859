import { Typography, Box } from '@mui/material';
import logo_celtara from '../../images/logo_celtara.png';

function App() {
  return (
    <div>
      <img src={logo_celtara} alt="Logo" style={{ maxWidth: '200px' }} />
      <Box sx={{ p: 3 }}>
        <Typography variant="h5" gutterBottom>
          ¿Cómo borrar cuenta en la App?
        </Typography>
        <Typography variant="body1" paragraph>
          1. Abre la app e inicia sesión ingresando tu
          {' '}
          <strong>email</strong>
          {' '}
          y
          {' '}
          <strong>password</strong>
          .
        </Typography>
        <Typography variant="body1" paragraph>
          2. Una vez logueado, en el menú inferior, busca la opción de
          {' '}
          <strong>Perfil</strong>
          .
        </Typography>
        <Typography variant="body1" paragraph>
          3. En el perfil, verás tu
          {' '}
          <strong>nombre</strong>
          ,
          {' '}
          <strong>email</strong>
          ,
          {' '}
          <strong>celular</strong>
          {' '}
          y otros datos.
        </Typography>
        <Typography variant="body1" paragraph>
          4. Haz clic en la opción de
          {' '}
          <strong>Configuración</strong>
          .
        </Typography>
        <Typography variant="body1" paragraph>
          5. Allí encontrarás la opción de
          {' '}
          <strong>Borrar mi cuenta</strong>
          .
        </Typography>
      </Box>

      {/* Tutorial para la Web */}
      <Box sx={{ p: 3 }}>
        <Typography variant="h5" gutterBottom>
          ¿Cómo borrar cuenta en la Web?
        </Typography>
        <Typography variant="body1" paragraph>
          1. Entra a nuestro sitio e inicia sesión con tu
          {' '}
          <strong>email</strong>
          {' '}
          y
          {' '}
          <strong>password</strong>
          .
        </Typography>
        <Typography variant="body1" paragraph>
          2. En el menú lateral izquierdo, busca la opción de
          {' '}
          <strong>Ajustes</strong>
          .
        </Typography>
        <Typography variant="body1" paragraph>
          3. Allí aparecerá la opción de
          {' '}
          <strong>Borrar cuenta</strong>
          .
        </Typography>
        <Typography variant="body1" paragraph>
          4. Al hacer clic, aparecerá una advertencia indicando que tu perfil se eliminará permanentemente. Confirma la acción seleccionando
          {' '}
          <strong>Sí</strong>
          .
        </Typography>
      </Box>
    </div>
  );
}

export default App;
