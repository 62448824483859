import * as React from 'react';
import {
  Button, Stepper, Step, StepLabel, Stack, CardContent,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Swal from 'sweetalert2';
import SelectForm from './src/AppointmentSteps/selectForm';
import AppointmentForm from './src/AppointmentSteps/formVisit';
import ConfirmForm from './src/AppointmentSteps/confirmVisit';
import PageTitle from '../../components/shared/PageTitle';
import { isCompleteProfile } from '../../utils/Validate';
import { LoadingNewVisit } from '../../utils/loadingsFormats';

const steps = ['Elegir', 'Llenar formulario', 'Confirmar'];

export default function CreateVisit() {
  const history = useHistory();
  const { state }: any = history.location;
  const {
    doctorData, patientData, doctorSchedule,
  }: any = state ?? {
    doctorData: {
      doctorId: '', name: '', picture: '', department: '', rating: '',
    },
    patientData: {
      userId: '', name: '', image: '', phone: '', email: '',
    },
    doctorSchedule: {
      schedules: '', busyDates: '', datesAvailable: '', levelLocal: '',
    },
  };
  const State = useSelector((GenState: any) => GenState);

  const { auth: user } = State;
  const userRole = user.roles[0];
  const pathEditProfile = userRole == 'User' ? '/profile/edit' : '/profile/doctor/edit';

  const Styles = {
    color: userRole == 'User' ? '#03A9DB' : '#936AD0',
    stepperColor: userRole == 'User' ? '#936AD0' : '#03A9DB',
    iconColor: userRole == 'User' ? '#936AD0' : '#03A9DB',
    shareButtonColor: userRole == 'User' ? '#936AD0' : '#03A9DB',
  };
  const [activeStep, setActiveStep] = React.useState(doctorData.doctorId != '' ? 1 : 0);
  const [userDocData, setUserDocData] = React.useState<any>(null);
  const [doctor, setDoctor] = React.useState<any>({
    id: doctorData.doctorId != '' ? `${doctorData.doctorId}` : '',
    name: doctorData.name != '' ? `${doctorData.name}` : '',
    picture: doctorData.picture != '' ? `${doctorData.picture}` : '',
    department: doctorData.department != '' ? `${doctorData.department}` : '',
    rating: doctorData.rating != '' ? `${doctorData.rating}` : '',
    levelLocal: doctorSchedule.levelLocal[0] != '' ? doctorSchedule.levelLocal : '',
    address: doctorSchedule.address != '' ? doctorSchedule.address : '',
    costPerAppointment: doctorSchedule.costPerAppointment != '' ? doctorSchedule.costPerAppointment : '',
    urlMap: doctorSchedule.urlMap != '' ? doctorSchedule.urlMap : '',
  });
  const [doctorSchedules, setDoctorSchedules] = React.useState<any>({
    schedules: doctorSchedule.schedules[0] != '' ? doctorSchedule.schedules : '',
    busyDates: doctorSchedule.busyDates[0] != '' ? doctorSchedule.busyDates : '',
    datesAvailable: doctorSchedule.datesAvailable[0] != '' ? doctorSchedule.datesAvailable : '',
  });
  const [patient, setPatient] = React.useState<any>({
    userId: patientData.userId != '' ? `${patientData.userId}` : '',
    name: patientData.name != '' ? `${patientData.name}` : '',
    image: patientData.image != '' ? `${patientData.image}` : '',
    phone: patientData.phone != '' ? `${patientData.phone}` : '',
    email: patientData.email != '' ? `${patientData.email}` : '',
    reason: '',
    comments: '',
    date: '',
    hour: '',
  });

  React.useEffect(() => {
    if (!isCompleteProfile(user, userRole) && user.status == 'authenticated') {
      Swal.fire('Perfil incompleto', 'Para generar una cita es necesario completar su perfil.', 'info').then((okay) => {
        if (okay) { window.location.replace(pathEditProfile); }
      });
    }
  }, []);

  React.useEffect(() => {
    if (userRole == 'Subordinate') {
      setUserDocData(user.doctorProfileData);
    } else setUserDocData(user);
  }, [userRole]);

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return (
          <SelectForm
            onSubmit={(userData, schedulesData) => {
              setDoctor({
                id: userRole == 'User' ? `${userData.id}` : `${userDocData.userId}`,
                name: userRole == 'User' ? `${userData.name}` : `${userDocData.name}`,
                picture: userRole == 'User' ? `${userData.picture || userData.url}` : `${userDocData.url}`,
                department: userRole == 'User' ? `${userData.department[0].value}` : `${userDocData.department[0].value}`,
                rating: userRole == 'User' ? `${userData.rating || '5.0'}`
                  : `${userDocData.rating || '5.0'}`,
                levelLocal: schedulesData.levelLocal,
                address: schedulesData.address,
                costPerAppointment: schedulesData.costPerAppointment,
                urlMap: schedulesData.urlMap,
              });
              setDoctorSchedules(schedulesData);
              setPatient({
                ...patient,
                userId: userRole == 'User' ? `${user.userId}` : `${userData.id}`,
                name: userRole == 'User' ? `${user.name}` : `${userData.firstName} ${userData.lastName}`,
                image: userRole == 'User' ? `${user.url}` : `${userData.image}`,
                phone: userRole == 'User' ? `${user.phone}` : `${userData.phone}`,
                email: userRole == 'User' ? `${user.email}` : `${userData.email}`,
              });
              setActiveStep((prev) => prev + 1);
            }}
          />
        );
      case 1:
        return (
          <AppointmentForm
            userSelected={userRole == 'User' ? doctor : patient}
            doctorSchedules={doctorSchedules}
            onSubmit={(values) => {
              setPatient({
                ...patient,
                reason: `${values.reason}`,
                comments: `${values.comments != '' ? values.comments : 'Sin comentarios'}`,
                date: values.date,
                hour: values.hour,
              });
              setActiveStep((prev) => prev + 1);
            }}
            onBack={() => handleBack()}
          />
        );
      case 2:
        return (
          <ConfirmForm
            doctor={doctor}
            patient={patient}
            onSubmit={(data) => {
              setActiveStep((prev) => prev + 1);
              setTimeout(() => { history.push('/visit/created', { visitId: data.visitId }); }, 2000);
            }}
            onCancel={() => setActiveStep(0)}
          />
        );
      default:
        throw new Error('Unknown step');
    }
  }

  return (
    <>
      <>
        {activeStep === steps.length ? (
          <></>
        ) : (
          <>
            <PageTitle
              kLeft={activeStep !== 0
                ? (
                  <Button onClick={handleBack} color={userRole == 'User' ? 'primary' : 'secondary'}>
                    <ArrowBackIosIcon />
                  </Button>
                )
                : null}
              kTitle={activeStep == 2 ? 'Verificar tu nueva cita' : 'Crear Nueva Cita'}
            />
            <CardContent>
              <Stepper
                activeStep={activeStep}
                alternativeLabel
                sx={{
                  py: 3,
                  '& .MuiStepIcon-root.Mui-completed': { color: '#6AD087' },
                  '& .MuiStepIcon-root.Mui-active': { color: Styles.stepperColor },
                }}
              >
                {steps.map((label, i: number) => (
                  <Step key={label}>
                    {i == 0 ? (
                      <StepLabel>
                        {userRole == 'User' ? `${label} doctor` : `${label} paciente`}
                      </StepLabel>
                    ) : (<StepLabel>{label}</StepLabel>)}
                  </Step>
                ))}
              </Stepper>
            </CardContent>
          </>
        )}
        {activeStep === steps.length ? (
          <>
            <PageTitle kTitle="Generando cita" />
            <Stack sx={{ color: 'grey.500', minHeight: 600 }} justifyContent="center" alignItems="center" spacing={1} direction="column">
              <LoadingNewVisit color="secondary" />
            </Stack>
          </>
        ) : (
          <>
            {getStepContent(activeStep)}
          </>
        )}
      </>
    </>
  );
}
