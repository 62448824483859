/* eslint-disable import/no-unresolved */
import {
  Typography,
  Stack,
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import {
  academicListDTO,
  AcademicsListProps, CostPerAppointmentProps, SchedulesListProps, departmentDTO, SubDepartmentsListProps,
} from '../../profile.model';

export function DepartmentsList(props: SubDepartmentsListProps) {
  return (
    <>
      <Stack
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
        direction="row"
        my={2}
      >
        <CheckCircleOutlineIcon color={props.color} />
        <Typography variant="body2" color="textSecondary">
          Especialidad
        </Typography>
      </Stack>
      {props.data.map((value: departmentDTO, index, array) => (
        <Typography
          component="span"
          variant="body2"
          color="textPrimary"
          sx={{ textTransform: 'capitalize' }}
          key={value.key}
        >
          {value.value}
          {array.length - 1 === index ? '.' : ', '}
        </Typography>
      ))}
    </>
  );
}

export function SubDepartmentsList(props: SubDepartmentsListProps) {
  return (
    <>
      <Stack
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
        direction="row"
        my={2}
      >
        <CheckCircleOutlineIcon color={props.color} />
        <Typography variant="body2" color="textSecondary">
          Sub Especialidad
        </Typography>
      </Stack>
      {props.data.map((value: departmentDTO, index, array) => (
        <Typography
          component="span"
          variant="body2"
          color="textPrimary"
          sx={{ textTransform: 'capitalize' }}
          key={value.key}
        >
          {value.value}
          {array.length - 1 === index ? '.' : ', '}
        </Typography>
      ))}
    </>
  );
}

export function AcademicsListData(props: AcademicsListProps) {
  return (
    <>
      <Stack
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
        direction="row"
        my={2}
      >
        <SchoolOutlinedIcon color={props.color} />
        <Typography variant="body2" color="textSecondary">
          Estudios Académicos Alcanzados
        </Typography>
      </Stack>
      {props.data.length > 0
        ? props.data.map((value: academicListDTO, index, array) => (
          <Typography
            component="span"
            variant="body2"
            color="textPrimary"
            sx={{ textTransform: 'capitalize' }}
            key={value.academicStudiesId}
          >
            {`${value.degree} (${value.school})`}
            {array.length - 1 === index ? '.' : ', '}
          </Typography>
        ))
        : null }
    </>
  );
}

export function SchedulesListData(props: SchedulesListProps) {
  const { data } = props;
  return (
    <>
      <Stack
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
        direction="row"
        my={2}
      >
        <AccessTimeOutlinedIcon color={props.color} />
        <Typography variant="body2" color="textSecondary">
          Horarios de Atención
        </Typography>
      </Stack>

      {data.map((value) => (
        <Stack
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
          direction="row"
          mb={1.5}
        >
          <Typography sx={{ minWidth: '70px' }} variant="body2" color="textPrimary" key={value.orderDay}>
            {`${value.day}:`}
          </Typography>
          <Stack
            justifyContent="flex-start"
            alignItems="center"
            spacing={0}
            direction="column"
          >
            <Typography
              variant="body2"
              color="textPrimary"
              key={value.orderDay}
            >
              {` ${value.hourOpen} - ${value.hourClose} hrs.`}
            </Typography>
            {value.secondHourClose && value.secondHourOpen
              ? (
                <Typography
                  variant="body2"
                  color="textPrimary"
                  key={value.orderDay}
                >
                  {` ${value.secondHourOpen} - ${value.secondHourClose} hrs.`}
                </Typography>
              )

              : <></>}

          </Stack>
        </Stack>
      ))}

    </>
  );
}

export function CostPerAppointmentData(props: CostPerAppointmentProps) {
  const { data, color } = props;
  return (
    <>
      <Stack
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
        direction="row"
        my={2}
      >
        <PaidOutlinedIcon color={color} />
        <Typography variant="body2" color="textSecondary">
          Costo Por Hora
        </Typography>
        <Typography
          component="p"
          variant="body2"
          color="textPrimary"
        >
          {`$${data != null ? data : 0}.00`}
        </Typography>
      </Stack>

    </>
  );
}
