/* eslint-disable no-nested-ternary */

import * as React from 'react';
import {
  Avatar, Box, Button, CircularProgress, List, ListItem,
  ListItemAvatar,
  ListItemText, Stack,
  Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography,
} from '@mui/material';
import moment from 'moment';
import CustomModal from '../../../../components/shared/Modal';
import PatientProfile from '../profiles/patientProfile';
import { TablePatientDataProps } from '../../profile.model.d';

export default function TablePatientData(props: TablePatientDataProps) {
  const {
    ListData, hasMore, totalCount, onSubmit, onLoadMore, isMobile, isLoadingMore, page,
  } = props;
  const [openModal, setOpenModal] = React.useState(false);
  const [idProfile, setIdProfile] = React.useState<any>();

  const handleChangePage = (_event: any, newPage: number) => {
    if (onSubmit) {
      onSubmit(newPage);
    }
  };

  const handleClick = (userId: string) => () => {
    setIdProfile(userId);
    setOpenModal(true);
  };

  return (
    <>
      {!isMobile && (
        <Box sx={{ py: 2 }}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Género</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {ListData.map((list) => (
                  <TableRow
                    key={list.id}
                    sx={{ th: { border: 0, whiteSpace: 'nowrap' } }}
                  >
                    <TableCell component="th" scope="row">
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Avatar src={list.image} sx={{ width: 32, height: 32 }} alt={list.firstName} />
                        <Stack direction="column" alignItems="flex-start" spacing={0}>
                          <Typography color="textPrimary" variant="body2" sx={{ maxWidth: '130px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {list.firstName}
                            {list.lastName}
                          </Typography>
                        </Stack>
                      </Stack>
                    </TableCell>
                    <TableCell component="th" scope="row">{list.gender[0]}</TableCell>
                    <TableCell component="th" scope="row">{list.email}</TableCell>
                    <TableCell component="th" scope="row">
                      <Button
                        color="secondary"
                        variant="text"
                        sx={{ whiteSpace: 'nowrap' }}
                        onClick={handleClick(list.id)}
                      >
                        Ver Perfil
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {totalCount > 5 && (
              <TablePagination
                component="div"
                count={totalCount}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={5}
                rowsPerPageOptions={[5]}
                labelRowsPerPage=""
                nextIconButtonProps={{
                  disabled: (page + 1) * 5 >= totalCount,
                }}
              />
            )}
          </TableContainer>
        </Box>
      )}
      {isMobile && (
        <Box sx={{
          display: 'flex', flexDirection: 'column', alignItems: 'center', py: 1, marginLeft: 1,
        }}
        >
          <List sx={{
            width: '100%',
            bgcolor: 'background.paper',
            '& ul': { padding: 0 },
          }}
          >
            <Stack spacing={2}>
              {ListData.map((list: any) => (
                <ListItem
                  key={list.id}
                  secondaryAction={(
                    <Button
                      color="secondary"
                      variant="text"
                      sx={{ whiteSpace: 'nowrap' }}
                      onClick={handleClick(list.id)}
                    >
                      Ver Perfil
                    </Button>
                  )}
                  disablePadding
                >
                  <ListItemAvatar>
                    <Avatar alt={`${list.firstName}`} src={`${list.image}`} />
                  </ListItemAvatar>
                  <ListItemText
                    id={list.id}
                    primary={(
                      <Typography
                        variant="body2"
                        sx={{
                          maxWidth: '130px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',
                        }}
                      >
                        {list.firstName}
                        {list.lastName}
                      </Typography>
                    )}
                    secondary={(
                      <>
                        {list.visitDate != null
                          ? (
                            <Box sx={{ display: 'flex' }}>
                              <Box component="span" sx={{ display: { xs: 'none', sm: 'flex' } }}>
                                {'Última visita: '}
                              </Box>
                              <Box component="span">{moment(list.visitDate, ['YYYY-MM-DD']).format('DD/MM/yyyy')}</Box>
                            </Box>
                          )
                          : 'Programar cita'}
                      </>
                    )}
                  />
                </ListItem>
              ))}
            </Stack>
          </List>
          {hasMore && onLoadMore && (
            <Box
              sx={{
                display: 'flex', justifyContent: 'center', py: 2, width: '100%',
              }}
            >
              {isLoadingMore ? (
                <CircularProgress color="secondary" />
              ) : (
                <Button variant="contained" onClick={onLoadMore}>
                  Ver más
                </Button>
              )}
            </Box>
          )}
        </Box>
      )}
      <CustomModal
        handleModalToggle={() => setOpenModal(false)}
        open={openModal}
      >
        <PatientProfile userId={idProfile} private={false} showButtons />
      </CustomModal>
    </>
  );
}
